import { object, array, string, number, boolean } from 'yup';
import { CHARGE_TYPE, DELIVERY_CHARGES_FIELDS, GENERAL_CHARGES_FIELDS, RANGE_ERROR } from './constants';

export const deliveryChargeSchema = object({
    minDistance: number().required("Min distance is required").min(0, "Min distance must be zero or positive").test(
        'min-less-than-max',
        'Min distance must be less than the max distance',
        function (minDistance) {
            const { maxDistance } = this.parent;
            if (!maxDistance || !minDistance) return true;
            return Number(maxDistance) > Number(minDistance);
        }
    ),
    maxDistance: number().required("Max distance is required").positive("Max distance must be positive").test(
        'max-greater-than-min',
        'Max distance must be greater than the min distance',
        function (maxDistance) {
            const { minDistance } = this.parent;
            if (!maxDistance || !minDistance) return true;
            return Number(maxDistance) > Number(minDistance);
        }
    ),
    price: number().required("Amount is required").test(
        'minimum-price',
        'Amount must be greater than zero',
        function (price) {
            const { minDistance } = this.parent;
            const priceNumber = Number(price);
            if (Number(minDistance) === 0 && priceNumber >= 0) return true;
            else if (priceNumber > 0) return true;
            else return false;
        })
});
// min(0.00001, "Amount must be greater than zero"),


export const chargeSchema = object({
    name: string().required("Charge name is required"),
    value: number().required("Amount is required").min(0.00001, "Amount must be greater than zero"),
    orderType: number().required("Order type is required").min(1).max(4).integer(),
    isPercentage: boolean().required(),
    isActive: boolean().required(),
});


/**
 * Validates the name of a charge and updates the error state accordingly.
 * @param {Array} charges - The array of charge objects that the name belongs to.
 * @param {Object} error - The error state object to update.
 * @param {number} index - The index of the charge in the newCharges array.
 * @returns {void} - it mutates the error object that was passed to it
 */
export const validateName = (charges, error, index) => {
    try {
        chargeSchema.validateSyncAt("name", charges[index]);
        delete error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.NAME}`];
    } catch (e) {
        error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.NAME}`] = e.message;
    }
}

/**
 * Validates the amount of a charge and updates the error state accordingly.
 * @param {Array} charges - The array of charge objects that the amount belongs to.
 * @param {Object} error - The error state object to update.
 * @param {number} index - The index of the charge in the newCharges array.
 * @returns {void} - it mutates the error object that was passed to it
 */
export const validateAmount = (charges, error, index) => {
    try {
        chargeSchema.validateSyncAt("value", charges[index]);
        delete error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.VALUE}`];
    } catch (e) {
        error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.VALUE}`] = e.message;
    }
}

/**
 * Validates the order type of a charge and updates the error state accordingly.
 * @param {Array} charges - The array of charge objects that the order type belongs to.
 * @param {Object} error - The error state object to update.
 * @param {number} index - The index of the charge in the newCharges array.
 * @returns {void} - it mutates the error object that was passed to it
 */
export const validateOrderType = (charges, error, index) => {
    try {
        chargeSchema.validateSyncAt("orderType", charges[index]);
        delete error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.ORDER_TYPE}`];
    } catch (e) {
        error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.ORDER_TYPE}`] = e.message;
    }
}

/**
 * Validates the minimum distance of a delivery charge and updates the error state accordingly.
 * @param {Array} deliveryCharges - The array of all delivery charge objects.
 * @param {Object} error - The error state object to update.
 * @param {number} index - The index of the delivery charge in the deliveryCharges array.
 * @returns {void} - It mutates the error object that was passed to it.
 */

export const validateDeliveryDistances = (deliveryCharges, error, index) => {
    try {
        deliveryChargeSchema.validateSyncAt('minDistance', deliveryCharges[index]);
        if (index >= 1) {
            const perviousCharge = deliveryCharges[index - 1];
            if (Number(perviousCharge.maxDistance) !== Number(deliveryCharges[index].minDistance))
                throw new Error('Each delivery charge range must start from previous range ends')
        }
        delete error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.MIN}`];
    } catch (e) {
        error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.MIN}`] = e.message;
    }

    try {
        deliveryChargeSchema.validateSyncAt('maxDistance', deliveryCharges[index]);
        delete error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.MAX}`];
    } catch (e) {
        error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.MAX}`] = e.message;
    }
}

/**
 * Validates the price of a delivery charge and updates the error state accordingly.
 * @param {Array} deliveryCharges - The array of all delivery charge objects.
 * @param {Object} error - The error state object to update.
 * @param {number} index - The index of the delivery charge in the deliveryCharges array.
 * @returns {void} - It mutates the error object that was passed to it.
 */

export const validateDeliveryAmount = (deliveryCharges, error, index) => {
    try {
        deliveryChargeSchema.validateSyncAt('price', deliveryCharges[index]);
        delete error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.PRICE}`];
    } catch (e) {
        error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.PRICE}`] = e.message;
    }
}

export const checkDeliveryDistances = (deliveryCharges, maxDeliveryDistance) => {
    const chargesArray = [...deliveryCharges];
    chargesArray.sort((a, b) => a.minDistance - b.minDistance);

    const missingRanges = [];
    let currentStart = 0;

    for (let charge of chargesArray) {
        if (charge.minDistance > currentStart) {
            missingRanges.push({ start: currentStart, end: charge.minDistance });
        }

        currentStart = Math.max(currentStart + 1, charge.maxDistance);
    }

    if (maxDeliveryDistance !== Number.MAX_SAFE_INTEGER && currentStart < maxDeliveryDistance) {
        missingRanges.push({ start: currentStart, end: maxDeliveryDistance });
    }

    return missingRanges;
}

