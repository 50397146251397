import React from 'react'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const ChargeListHeader = ({ chargeTitle, styles, theme_mode, addHandler, displayMessage = null }) => {
    return (
        <Grid container item xs={12} >
            <Grid container xs={12}>
                <Grid item xs={5}>
                    <Typography sx={{ color: "#ffffff", fontSize: 16, fontWeight: 600 }}>{chargeTitle}</Typography>
                </Grid>
                <Grid container item xs={7} justifyContent={"flex-end"}>
                    <Box
                        className={styles.addBtnView}
                        sx={{
                            backgroundColor: theme_mode.black,
                            border: `1.5px solid ${theme_mode.border7}`,
                        }}
                        onClick={addHandler}
                    >
                        <Typography
                            className={styles.addBtnText}
                            sx={{
                                color: theme_mode.btnText,
                            }}
                            ml={0.5}
                        >
                            Add A Charge
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            {displayMessage && <Grid container xs={12} sx={{ backgroundColor: theme_mode.headerBg2 }}>
                <Typography sx={{
                    marginTop: '0.5rem',
                    padding: '0.25rem'
                }} color="error">{displayMessage}</Typography>
            </Grid>}
        </Grid>
    )
}

export default ChargeListHeader;
