import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { connect } from "react-redux";
import { updateFcmToken } from "./redux/actions/userDataActions";

import Login from "./containers/Auth/Login";
import PinLogin from "./containers/Auth/PinLogin";
import Logout from "./containers/Auth/Logout";
import Menu from "./containers/Menu/Menu";
import AddRestaurantInfo from "./containers/Restaurant/AddRestaurantInfo";
import RestaurantInfo from "./containers/Restaurant/RestaurantInfo";
import Settings from "./containers/Settings/Settings";
import SettingsMenu from "./containers/Settings/SettingsMenu";
import Events from "./containers/Settings/Events";
import AccountDetail from "./containers/Settings/AccountDetail";
import Users from "./containers/Settings/Users";
import Subscriptions from "./containers/Settings/Subscriptions";
import OrderTimeSettings from "./containers/Settings/OrderTimeSettings";
import Course from "./containers/Settings/Course";
import RestaurantSettings from "./containers/Settings/RestaurantSettings";
import OrderHistory from "./containers/Settings/OrderHistory";
import AddMenu from "./containers/Settings/AddMenu";
import AddCategory from "./containers/Settings/AddCategory";
import CategoryReorder from "./containers/Settings/CategoryReorder";
import OfflineSettings from "./containers/Settings/OfflineSettings";
import Printers from "./containers/Settings/Printers";
import Orders from "./containers/Dashboard/Orders";
import Dining from "./containers/Dashboard/Dining";
import TableOrders from "./containers/Dashboard/TableOrders";
import DiningDetails from "./containers/Dashboard/DiningDetails";
import OrderSummary from "./containers/Dashboard/OrderSummary/OrderSummary";
import GiftCards from "./containers/GiftCards/GiftCards";
import GiftCardHistory from "./containers/GiftCards/GiftCardHistory";
import TableBooking from "./containers/Bookings/TableBooking";
import BookingsHistory from "./containers/Bookings/BookingsHistory";
import AddBooking from "./containers/Bookings/AddBooking";
import Customers from "./containers/Customers/Customers";
import UserEnquiries from "./containers/Settings/UserEnquiries";
import Analytics from "./containers/Settings/Analytics";
import ViewItems from "./containers/Settings/ViewItems";
import CustomRestaurantInfo from "./containers/Settings/CustomRestaurantInfo";
import Version from "./containers/Version";
import FloorTableLayout from "./containers/Settings/FloorTableLayout";
import SalesReport from "./containers/Settings/SalesReport";
import Report from "./containers/Settings/Report";
import Modifiers from "./containers/Settings/Modifiers";
import TimerRegister from "./containers/Settings/TimerRegister";
import TakeAwaySettings from "./containers/Settings/TakeAwaySettings";
import Charges from "./containers/Settings/Charges";

import AlertMsg from "./components/AlertMsg";
import Container from "./components/Container";
import AlertUserRoleModal from "./components/AlertUserRoleModal";

import * as Api from "./api";

import {
  APP_TYPE_BOTH,
  APP_TYPE_GOPOSH,
  RESTAURANT_ADMIN_ROLE_ID,
  RESTAURANT_USER_ROLE_ID,
} from "./constants";

const App = (props) => {
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const clearTimerRef = useRef();
  const appType = props.restaurantDetail?.appType;

  useEffect(() => {
    inactivityTime();
  }, [props.pinLogin]);

  // const createBulkUpload = () => {
  //   let restaurant_id = "62cbfa1432a36825a9409279";
  //   //dummy data
  //   let datas = {
  //     orders: [
  //       {
  //         _idRestaurant: "62cbfa1432a36825a9409279",
  //         _idRestaurantTable: [
  //           "64a5531ac2197e37334d59e0",
  //           "64a5531ac2197e37334d59de",
  //         ],
  //         _idRestaurantUser: "64c3ecf3de16ae5910f0bfb8",
  //         restaurantUserName: "user name",
  //         _idRestaurantDevice: "64c600dade16ae5910f19594",
  //         restaurantDeviceName: "device name",
  //         orderStatus: "completed",
  //         orderType: 1, //1=>dineIn ,2=>delivery, 3=>pickUp, 4=>table
  //         description: "test New for Modifier",
  //         userDetails: {
  //           name: "test User nameeeee",
  //         },
  //         cart: [
  //           {
  //             _idMenuItem: "647f3955164c94224812b56d",
  //             menuItemName: "pasta",
  //             _idRestaurantCourse: "64a663225fa2ff4d77d12fe8",
  //             restaurantCourseName: "lunch",
  //             price: {
  //               value: 0,
  //               currency: "INR",
  //             },
  //             notes: "",
  //             options: "",
  //             quantity: 1,
  //             modifiers: [
  //               {
  //                 _idModifiers: "647f3955164c94224812b56d",
  //                 price: {
  //                   value: 50,
  //                   currency: "USD",
  //                   _id: "649eb1caf1ebdcaee790d44b",
  //                 },
  //               },
  //             ],
  //           },
  //         ],
  //         discountType: 1,
  //         discounts: [
  //           {
  //             discountValueType: 1,
  //             value: 20,
  //             _idMenuItem: "647f3955164c94224812b56d",
  //           },
  //           {
  //             discountValueType: 1,
  //             value: 10,
  //             _idMenuItem: "62cbfa4de2ee3125b1e56091",
  //           },
  //         ],
  //         totalDiscount: 30,
  //         totalpayment: 184.25,
  //         paymentType: 2, //1=>Single Payment, 2=>Split Payment
  //         splitType: 3, //1=>Equal Split, 2=>Split By 2, 3=>Split by Item
  //         splitPayment: [
  //           {
  //             paymentMethod: 1, //1=>Cash, 2=>E-Cash, 3=>Card, 4=>Gift Card
  //             paidAmount: 100,
  //             tip: 5,
  //             cashAmount: 105,
  //             change: 0,
  //             _idMenuItem: [
  //               "647f3955164c94224812b56d",
  //               "62cbfa4de2ee3125b1e56091",
  //             ],
  //           },
  //           {
  //             paymentMethod: 2, //1=>Cash, 2=>E-Cash, 3=>Card, 4=>Gift Card
  //             paidAmount: 100,
  //             tip: 0,
  //             cashAmount: 60,
  //             change: 5.75,
  //             _idMenuItem: ["62cbfa4de2ee3125b1e56091"],
  //             paymentSpecificData: {},
  //           },
  //         ],
  //       },
  //     ],
  //   };
  //   let data = JSON.stringify(datas);
  //   Api.createBulkUpload(data, restaurant_id).then((response) => {
  //     if (response.success) {
  //       //Success
  //     } else {
  //       //Error
  //     }
  //   });
  // };

  const inactivityTime = function () {
    clearTimeout(clearTimerRef.current);
    if (props.pinLogin && props.pinUserId) {
      resetTimer();
    }
    window.onload = resetTimer;
    document.onload = resetTimer;
    document.onmousemove = resetTimer;
    document.onmousedown = resetTimer; // touchscreen presses
    document.ontouchstart = resetTimer;
    document.onclick = resetTimer; // touchpad clicks
    document.onkeypress = resetTimer;
    document.addEventListener("scroll", resetTimer, true);
    function logout() {
      clearTimeout(clearTimerRef.current);
      if (props.pinLogin && props.pinUserId) {
        console.log("You are now logged out.");
        window.location.href = "/logout/pin";
      }
    }
    function resetTimer() {
      clearTimeout(clearTimerRef.current);
      if (props.pinLogin && props.pinUserId) {
        clearTimerRef.current = setTimeout(logout, 300000);
      } else {
        return null;
      }
    }
  };

  let routes = (
    <Routes>
      <Route exact path="/login" element={<Login {...props} />} />
      <Route exact path="/" element={<Navigate replace to="/login" />} />
      <Route path="/version" element={<Version />} />
      <Route path="*" element={<Navigate replace to="/login" />} />
    </Routes>
  );

  if (props.userToken) {
    if (props.restaurantId) {
      if (props.pinLogin) {
        let user_routes = (
          <>
            <Route exact path="/" element={<Orders {...props} />} />
            <Route exact path="/" element={<Orders {...props} />} />
            <Route exact path="/orders" element={<Orders {...props} />} />
            <Route exact path="/dining" element={<TableOrders {...props} />} />
            <Route
              exact
              path="/dining/details/:id"
              element={<DiningDetails {...props} />}
            />
            <Route
              exact
              path="/dining/orderSummary/:id"
              element={<OrderSummary {...props} />}
            />
            <Route
              exact
              path="/gift_cards"
              element={<GiftCards {...props} />}
            />
            <Route
              exact
              path="/gift_cards/history"
              element={<GiftCardHistory {...props} />}
            />
            <Route
              exact
              path="/bookings"
              element={<TableBooking {...props} />}
            />
            <Route
              exact
              path="/bookings/:id/add"
              element={<AddBooking {...props} />}
            />
            <Route
              exact
              path="/bookings/:id/cancel/:id"
              element={<AddBooking {...props} />}
            />
            <Route exact path="/customers" element={<Customers {...props} />} />
            <Route exact path="/logout/pin" element={<Logout {...props} />} />
            <Route path="/version" element={<Version />} />
          </>
        );

        let settings_route = (
          <>
            <Route exact path="/settings" element={<Settings {...props} />} />
            <Route
              exact
              path="/restaurant_info"
              element={<RestaurantInfo {...props} />}
            />
            <Route exact path="/events" element={<Events {...props} />} />
            <Route exact path="/add_menu" element={<AddMenu {...props} />} />
            <Route
              exact
              path="/add_menu/:id"
              element={<AddMenu {...props} />}
            />
            <Route
              exact
              path="/settings/menu"
              element={<SettingsMenu {...props} />}
            />
            <Route
              exact
              path="/settings/category"
              element={<SettingsMenu {...props} />}
            />
            <Route
              exact
              path="/subscriptions"
              element={<Subscriptions {...props} />}
            />
            <Route
              exact
              path="/category_reorder"
              element={<CategoryReorder {...props} />}
            />
            <Route exact path="/course" element={<Course {...props} />} />
            <Route exact path="/modifiers" element={<Modifiers {...props} />} />
            <Route
              exact
              path="/restaurant_settings"
              element={<RestaurantSettings {...props} />}
            />
            <Route
              exact
              path="/add_category"
              element={<AddCategory {...props} />}
            />
            <Route
              exact
              path="/add_category/:id"
              element={<AddCategory {...props} />}
            />
            <Route exact path="/users" element={<Users {...props} />} />
            <Route exact path="/timer_register" element={<TimerRegister {...props} />} />
            <Route
              exact
              path="/custom_restaurant_info"
              element={<CustomRestaurantInfo {...props} />}
            />
            <Route
              exact
              path="/takeaway_settings"
              element={<TakeAwaySettings {...props} />}
            />
            <Route
              exact
              path="/charges"
              element={<Charges {...props} />}
            />
          </>
        );
        if (appType === APP_TYPE_GOPOSH || appType === APP_TYPE_BOTH) {
          let goposh_settings_route = (
            <>
              <Route
                exact
                path="/order_history"
                element={<OrderHistory {...props} />}
              />
              <Route
                exact
                path="/floor_management"
                element={<FloorTableLayout {...props} />}
              />
              <Route
                exact
                path="/account_detail"
                element={<AccountDetail {...props} />}
              />
              <Route
                exact
                path="/order_time"
                element={<OrderTimeSettings {...props} />}
              />
              <Route
                exact
                path="/offline"
                element={<OfflineSettings {...props} />}
              />
              <Route exact path="/printers" element={<Printers {...props} />} />
              <Route
                exact
                path="/analytics"
                element={<Analytics {...props} />}
              />
              <Route
                exact
                path="/user_enquiries"
                element={<UserEnquiries {...props} />}
              />
              <Route
                exact
                path="/view_items"
                element={<ViewItems {...props} />}
              />
              <Route
                exact
                path="/bookings/history"
                element={<BookingsHistory {...props} />}
              />
              <Route
                exact
                path="/sales_report"
                element={<SalesReport {...props} />}
              />
              <Route exact path="/x_report" element={<Report {...props} />} />
              <Route exact path="/z_report" element={<Report {...props} />} />
            </>
          );

          settings_route = (
            <>
              {settings_route}
              {goposh_settings_route}
            </>
          );
        } else {
          user_routes = (
            <>
              <Route exact path="/logout/pin" element={<Logout {...props} />} />
              <Route path="/version" element={<Version />} />
            </>
          );
        }
        if (props.userRole == RESTAURANT_ADMIN_ROLE_ID) {
          routes = (
            <Routes>
              {user_routes}
              {settings_route}
              <Route
                path="*"
                element={
                  appType === APP_TYPE_GOPOSH || appType === APP_TYPE_BOTH ? (
                    <Navigate replace to="/dining" />
                  ) : (
                    <Navigate replace to="/settings" />
                  )
                }
              />
            </Routes>
          );
        } else if (props.userRole === RESTAURANT_USER_ROLE_ID) {
          routes = (
            <Routes>
              {user_routes}
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          );
        } else {
          routes = (
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <>
                    <AlertUserRoleModal
                      modelVisible={
                        props.restaurantId != "" && props.userRole == ""
                      }
                    />
                    <Container page={"dashboard"} {...props} />
                  </>
                }
              />
              <Route exact path="/logout/pin" element={<Logout {...props} />} />
              <Route path="/version" element={<Version />} />
              <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
          );
        }
      } else {
        routes = (
          <Routes>
            <Route exact path="/login/pin" element={<PinLogin {...props} />} />
            <Route exact path="/logout" element={<Logout {...props} />} />
            <Route path="/version" element={<Version />} />
            <Route path="*" element={<Navigate replace to="/login/pin" />} />
          </Routes>
        );
      }
    } else {
      routes = (
        <Routes>
          <Route
            exact
            path="/add_restaurant"
            element={<AddRestaurantInfo {...props} />}
          />
          <Route exact path="/logout" element={<Logout {...props} />} />
          <Route
            exact
            path="/"
            element={<Navigate replace to="/add_restaurant" />}
          />
          <Route path="/version" element={<Version />} />
          <Route path="*" element={<Navigate replace to="/add_restaurant" />} />
        </Routes>
      );
    }
  }

  return (
    <>
      <BrowserRouter>{routes}</BrowserRouter>
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userToken: state.userData.userToken,
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
    fcmToken: state.userData.fcmToken,
    userRole: state.userData.userRole,
    pinLogin: state.userData.pinLogin,
    pinUserId: state.userData.pinUserId,
    offlineEnable: state.userData.offlineEnable,
    ipAddress: state.userData.offlineIpAddress,
    port: state.userData.offlinePort,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateFcmToken: (data) => dispatch(updateFcmToken(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
