export const CHARGE_TYPE = {
    DELIVERY: "DELIVERY",
    OTHER: "OTHER"
}

export const DELIVERY_CHARGES_FIELDS = {
    MIN: "MIN_DISTANCE",
    MAX: "MAX_DISTANCE",
    PRICE: "PRICE",
}

export const GENERAL_CHARGES_FIELDS = {
    NAME: "CHARGE NAME",
    VALUE: "VALUE",
    PERCENTAGE: "IS PERCENTAGE",
    ACTIVE: "IS ACTIVE",
    ORDER_TYPE: "ORDER TYPE",
}

export const ORDER_TYPE_OPTIONS = [
    { label: "Dine In", value: 1 },
    { label: "Delivery", value: 2 },
    { label: "Pick Up", value: 3 },
    { label: "Table", value: 4 }
]

export const RANGE_ERROR = "range_error";
export const UNIQUE_ERROR = "unique_error";