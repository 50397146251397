import React from 'react'
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

import styles from "../Printers.module.css";
import SettingTextField from "../../../components/SettingTextField";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import { DELIVERY_CHARGES_FIELDS, CHARGE_TYPE } from "./constants";

const DeliveryChargeItem = ({ chargeItem, index, handleChange, error, drawerOpen, theme_mode, onDelete, maxRange, ...props }) => {
    return (
        <Grid
            key={chargeItem._id}
            container
            spacing={2}
            sx={{
                color: theme_mode.tableRowText,
                fontFamily: "InterMedium",
                fontSize: 14,
                border: 0,
                borderRadius: '0.75rem',
                padding: '0.5rem',
                marginTop: '1rem',
                paddingBottom: '1rem',
                marginLeft: 0,
                "&:nth-of-type(odd)": {
                    backgroundColor: theme_mode.tableRow2,
                },
                "&:nth-of-type(even)": {
                    backgroundColor: theme_mode.tableRow,
                },
            }}
        >
            <Grid item xs={12} sm={drawerOpen ? 12 : 6} xl={3} sx={{ padding: '0.5rem', sm: { minWidth: '25rem', color: 'red', background: 'purple' } }}>
                <SettingTextField
                    value={chargeItem.minDistance}
                    setValue={(e) => handleChange(e, index, DELIVERY_CHARGES_FIELDS.MIN)}
                    placeholder={""}
                    label={"Minimum Distance (in miles)"}
                    required={true}
                    labelStyle={styles.labelStyle}
                    variant={"filled"}
                    error={error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.MIN}`]}
                />
            </Grid>

            <Grid item xs={12} sm={drawerOpen ? 12 : 6} xl={3} sx={{ padding: '0.5rem', sm: { minWidth: '25rem', color: 'red', background: 'purple' } }}>
                <SettingTextField
                    value={chargeItem.maxDistance}
                    setValue={(e) => handleChange(e, index, DELIVERY_CHARGES_FIELDS.MAX)}
                    placeholder={""}
                    label={"Maximum Distance (in miles)"}
                    required={true}
                    labelStyle={styles.labelStyle}
                    variant={"filled"}
                    error={error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.MAX}`]}
                    rightView=<div>Max Range: {maxRange}</div>
                />
            </Grid>

            <Grid item xs={12} sm={drawerOpen ? 12 : 6} xl={3} sx={{ padding: '0.5rem', sm: { minWidth: '25rem' } }}>
                <SettingTextField
                    value={chargeItem.price}
                    setValue={(e) => handleChange(e, index, DELIVERY_CHARGES_FIELDS.PRICE)}
                    required={true}
                    label={"Amount"}
                    labelStyle={styles.labelStyle}
                    variant={"filled"}
                    error={error[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.PRICE}`]}
                    inputTextStyle={{ autoComplete: "off" }}
                    textAlign={chargeItem.isPercentage ? "right" : "left"}
                    leftView={!chargeItem.isPercentage &&
                        <Box sx={{ color: props.themeMode === "dark" ? "#ffffff" : "#000000" }}>£</Box>
                    }
                    rightView={chargeItem.isPercentage && <InputAdornment position="end" >
                        <Box sx={{ color: props.themeMode === "dark" ? "#ffffff" : "#000000" }}>%</Box>
                    </InputAdornment >}
                />
            </Grid>

            <Grid
                item
                xs={12}
                sm={6}
                xl={3}
                sx={{
                    padding: '0.5rem',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: '1rem',
                    alignItems: 'center',
                    justifyContent: { xs: 'center', md: 'flex-end' },
                    '& .MuiFormGroup-root': {
                        minWidth: 'fit-content'
                    },
                    sm: { minWidth: '25rem' }
                }}
            >
                <Box
                    className={styles.actionBox}
                    sx={{
                        alignSelf: { xs: 'flex-start', md: 'center' },
                        path: {
                            stroke: theme_mode.iconBg2,
                        }
                    }}
                >
                    <DeleteIcon
                        height={20}
                        width={20}
                        style={{ cursor: "pointer", color: 'red' }}
                        onClick={() => onDelete({ index, type: CHARGE_TYPE.DELIVERY })}
                    />
                </Box>
            </Grid>
        </Grid>
    )
}

export default DeliveryChargeItem;
