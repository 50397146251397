import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Button from "@mui/material/Button";
import styles from "./Printers.module.css";

import * as Api from "../../api";

import Container from "../../components/Container";
import Loader from "../../components/Loader";
import DiningConfirmAlert from "../../components/DiningConfirmAlert";
import AlertMsg from "../../components/AlertMsg";
import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { FOOTER_XPADDING } from "../../constants";
import { THEME_MODE } from "../../constants/Theme";
import { updateRestaurantDetail } from "../../redux/actions/userDataActions";
import ChargeListHeader from "./ChargesComponents/ChargesHeader";
import DeliveryChargeItem from "./ChargesComponents/DeliveryChargeItem";
import OtherChargeItem from "./ChargesComponents/OtherChargeItem";
import { CHARGE_TYPE, DELIVERY_CHARGES_FIELDS, GENERAL_CHARGES_FIELDS, RANGE_ERROR, UNIQUE_ERROR } from "./ChargesComponents/constants";
import {
    validateName,
    validateAmount,
    validateOrderType,
    validateDeliveryDistances,
    validateDeliveryAmount,
    checkDeliveryDistances
} from "./ChargesComponents/validatorFunctions";

const getRangeErrorMessage = (deliveryCharge, maxDeliveryRadius) => {
    const missingRanges = checkDeliveryDistances(deliveryCharge, maxDeliveryRadius);
    return missingRanges.length > 0 ?
        "Missing charges for delivery ranges: " + missingRanges.map(range => `${range.start}-${range.end}`).join(", ") + " miles" : ""
}

const ChargesSettings = ({ drawerOpen, ...props }) => {
    const theme_mode = THEME_MODE[props.themeMode];
    const styles1 = styles2(theme_mode);

    const navigate = useNavigate();
    const maxDeliveryRadius = props.restaurantDetail?.deliveryRadius ?? Number.MAX_SAFE_INTEGER;

    const [loading, setLoading] = useState(false);
    const [deliveryCharges, setDeliveryCharges] = useState(props.restaurantDetail?.deliveryCharge ? structuredClone(props.restaurantDetail.deliveryCharge) : []);
    const [charges, setCharges] = useState([...(structuredClone(props.restaurantDetail?.charges ?? []))]);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
    const [error, setError] = useState({});
    const [rangeError, setRangeError] = useState(getRangeErrorMessage(deliveryCharges, maxDeliveryRadius));

    const [alertConfirmModalData, setAlertConfirmModalData] = useState(null);
    const [msgAlert, setMsgAlert] = useState({
        open: false,
        message: "",
        msgType: "error",
    });

    const toggleConfirmModal = (data) => {
        setAlertConfirmModalData(data);
    };

    /**
     * Compares the local charges data with the server charges data to determine if there are any changes.
     * 
     * @param {Array} charges - The array of charge objects to compare with the server data.
     * @returns {boolean} - Returns true if there are any changes between the local and server charges data, otherwise false.
     */
    const serverChargesDataChanged = (charges, deliveryCharges) => {
        const serverCharges = props.restaurantDetail?.charges ?? [];
        if (!serverCharges.length && !charges.length) return false;
        if (serverCharges.length !== charges.length) return true;
        for (let i = 0; i < charges.length; i++) {
            if (serverCharges[i].name !== charges[i].name) return true;
            if (serverCharges[i].value !== charges[i].value) return true;
            if (serverCharges[i].isPercentage !== charges[i].isPercentage) return true;
            if (serverCharges[i].isActive !== charges[i].isActive) return true;
            if (serverCharges[i].orderType !== charges[i].orderType) return true;
        }

        const serverDeliveryCharge = props.restaurantDetail?.deliveryCharge ?? [];
        if (!serverDeliveryCharge.length && !deliveryCharges.length) return false;
        if (serverDeliveryCharge.length !== deliveryCharges.length) return true;
        for (let i = 0; i < deliveryCharges.length; i++) {
            if (serverDeliveryCharge[i].minDistance !== deliveryCharges[i].minDistance) return true;
            if (serverDeliveryCharge[i].maxDistance !== deliveryCharges[i].maxDistance) return true;
            if (Number(serverDeliveryCharge[i].price) !== Number(deliveryCharges[i].price)) return true;
        }
        return false;
    }


    /**
     * Handles value change for a charge field
     * @param {any} event The new value of the field
     * @param {number} index The index of the charge in the charges array
     * @param {string} field The name of the charge field that changed
     * @returns {void}
     */
    const handleValueChange = (event, index, field) => {
        const newCharges = [...charges];
        newCharges[index] = { ...newCharges[index] };

        const newError = { ...error };

        switch (field) {
            case GENERAL_CHARGES_FIELDS.NAME:
                newCharges[index].name = event;
                validateName(newCharges, newError, index);
                checkForUniqueness(newCharges, newError);
                break;
            case GENERAL_CHARGES_FIELDS.VALUE:
                if (isNaN(event)) return;
                newCharges[index].value = event;
                if (event === "") newError[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.VALUE}`] = "Amount is required";
                else {
                    validateAmount(newCharges, newError, index);
                }
                break;
            case GENERAL_CHARGES_FIELDS.ORDER_TYPE:
                newCharges[index].orderType = event;
                validateOrderType(newCharges, newError, index);
                checkForUniqueness(newCharges, newError);
                break;
            case GENERAL_CHARGES_FIELDS.PERCENTAGE:
                // No validation as the request comes from a switch component
                newCharges[index].isPercentage = event.target.checked;
                break;
            case GENERAL_CHARGES_FIELDS.ACTIVE:
                // No validation as the request comes from a switch component
                newCharges[index].isActive = event.target.checked;
                break;
            default:
                break;
        }
        if (serverChargesDataChanged(newCharges, deliveryCharges)) setSaveBtnDisabled(false);
        setCharges(newCharges);
        setError(newError);
    };

    const handleDeliveryChargeChange = (value, index, field) => {
        if (isNaN(value)) return;

        const newDeliveryCharges = [...deliveryCharges];
        const newError = { ...error };

        switch (field) {
            case DELIVERY_CHARGES_FIELDS.MIN:
                newDeliveryCharges[index].minDistance = value;
                validateDeliveryDistances(newDeliveryCharges, newError, index);
                break;
            case DELIVERY_CHARGES_FIELDS.MAX:
                newDeliveryCharges[index].maxDistance = value;
                validateDeliveryDistances(newDeliveryCharges, newError, index);
                if (maxDeliveryRadius && Number(value) > maxDeliveryRadius) newDeliveryCharges[index].maxDistance = maxDeliveryRadius
                if (rangeError) {
                    setRangeError(getRangeErrorMessage(newDeliveryCharges, maxDeliveryRadius));
                }
                break;
            case DELIVERY_CHARGES_FIELDS.PRICE:
                newDeliveryCharges[index].price = value;
                if (value === "") newError[`${CHARGE_TYPE.DELIVERY}_${index}_${DELIVERY_CHARGES_FIELDS.PRICE}`] = "Amount is required";
                else validateDeliveryAmount(newDeliveryCharges, newError, index);
                break;
            default:
                break;
        }

        if (serverChargesDataChanged(newDeliveryCharges, deliveryCharges)) setSaveBtnDisabled(false);
        setDeliveryCharges(newDeliveryCharges);
        setError(newError);
    }

    const onAddCharge = (type) => () => {
        if (type === CHARGE_TYPE.DELIVERY) {
            if (deliveryCharges.length === 0) setDeliveryCharges([{
                minDistance: 0,
                maxDistance: null,
                price: null,
            }]);
            else {
                const lastDeliveryCharge = deliveryCharges[deliveryCharges.length - 1];

                if (!lastDeliveryCharge.maxDistance || error[`${CHARGE_TYPE.DELIVERY}_${deliveryCharges.length - 1}_${DELIVERY_CHARGES_FIELDS.MAX}`]) {
                    setMsgAlert({ open: true, message: "Please enter a valid max distance before adding a new charge", msgType: "error" });
                    return;
                }
                if (!lastDeliveryCharge.price || error[`${CHARGE_TYPE.DELIVERY}_${deliveryCharges.length - 1}_${DELIVERY_CHARGES_FIELDS.PRICE}`]) {
                    setMsgAlert({ open: true, message: "Please enter a valid price before adding a new charge", msgType: "error" });
                    return;
                }


                setDeliveryCharges([...deliveryCharges, {
                    minDistance: lastDeliveryCharge.maxDistance ? Number(lastDeliveryCharge.maxDistance) : null,
                    maxDistance: null,
                    price: null
                }]);
                return;
            }
        } else {
            if (charges.length > 0 && !charges[charges.length - 1].name && !charges[charges.length - 1].amount) return;
            setCharges([...charges, { name: "", amount: 0, percentage: false, active: false }]);
        }
    };

    const onDeleteCharge = ({ index, type }) => {
        setAlertConfirmModalData({ index, type });
    }

    const onConfirmDelete = () => {
        if (alertConfirmModalData.type === CHARGE_TYPE.DELIVERY) {
            const newDeliveryCharges = [...deliveryCharges];
            newDeliveryCharges.splice(alertConfirmModalData.index, 1);
            setDeliveryCharges(newDeliveryCharges);
            setSaveBtnDisabled(!serverChargesDataChanged(charges, newDeliveryCharges));
        } else {
            const newCharges = [...charges];
            newCharges.splice(alertConfirmModalData.index, 1);
            setCharges(newCharges);
            setSaveBtnDisabled(!serverChargesDataChanged(newCharges, deliveryCharges));
        }
        toggleConfirmModal(null);
    }

    const onCancel = () => {
        setSaveBtnDisabled(true);
        setCharges([...structuredClone(props.restaurantDetail?.charges ?? [])]);
        setDeliveryCharges([...structuredClone(props.restaurantDetail?.deliveryCharge ?? [])]);
        setError({});
    }

    const checkForUniqueness = (charges, error) => {
        const uniqueCharges = new Map();
        for (let i = 0; i < charges.length; i++) {
            const name = charges[i].name?.trim().toLowerCase() ?? "";
            const orderType = charges[i].orderType ?? "";
            const uniqueKey = `${name}-${orderType}`;
            if (name && orderType) {
                if (!uniqueCharges.has(uniqueKey)) {
                    uniqueCharges.set(uniqueKey, [i]);
                    delete error[`${CHARGE_TYPE.OTHER}_${i}_${UNIQUE_ERROR}`];
                } else {
                    const keys = uniqueCharges.get(uniqueKey);
                    const newKeys = [...keys, i];
                    uniqueCharges.set(uniqueKey, newKeys);
                    for (const key of newKeys) {
                        error[`${CHARGE_TYPE.OTHER}_${key}_${UNIQUE_ERROR}`] = "Charge name and Category combination must be unique";
                    }
                }
            } else {
                delete error[`${CHARGE_TYPE.OTHER}_${i}_${UNIQUE_ERROR}`];
                continue;
            }
        }
    }

    const onSaveCharges = async () => {
        const newError = { ...error };

        checkForUniqueness(charges, newError);

        for (let i = 0; i < charges.length; i++) {
            validateName(charges, newError, i);
            validateAmount(charges, newError, i);
            validateOrderType(charges, newError, i);
        }

        for (let i = 0; i < deliveryCharges.length; i++) {
            validateDeliveryDistances(deliveryCharges, newError, i, maxDeliveryRadius);
            validateDeliveryAmount(deliveryCharges, newError, i);
        }


        if (Object.keys(newError).length > 0) {
            setError(newError);
        } else {
            setRangeError(getRangeErrorMessage(deliveryCharges, maxDeliveryRadius));
            onSubmit();
        }
    }

    const onSubmit = () => {
        setLoading(true);
        const data = {
            charges: charges,
            deliveryCharge: deliveryCharges
        }
        Api.updateRestaurant(data, props.restaurantId).then((response) => {
            if (response.success) {
                props.updateRestaurantDetail({
                    ...structuredClone(props.restaurantDetail),
                    charges: [...structuredClone(charges)],
                    deliveryCharge: deliveryCharges
                });
                setSaveBtnDisabled(true);
                setMsgAlert({ open: true, message: response.msg, msgType: "success" });
            } else {
                setMsgAlert({ open: true, message: response.msg, msgType: "error" });
            }
            setLoading(false);
        }).finally(() => {
            setLoading(false);
            setError({});
        });
    }

    return (
        <Container
            page={"charges"}
            navTitle={"Charges"}
            p={1.5}
            fpx={FOOTER_XPADDING[props.themeMode]}
            fpy={0}
        >
            {loading ? (
                <Loader />
            ) : (
                <Grid
                    p={1.5}
                    style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
                >
                    <Grid mb={2} container item xs={12}>
                        <Grid item xs={5}>
                            <Box
                                onClick={() => navigate("/settings")}
                                sx={{ cursor: "pointer", width: "fit-content" }}
                            >
                                <BackBtnIcon width={33} height={33} />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid
                        p={1.5}
                        style={{ backgroundColor: theme_mode.card3, borderRadius: 15 }}
                    >
                        <ChargeListHeader
                            chargeTitle="Delivery Charges"
                            styles={styles}
                            theme_mode={theme_mode}
                            addHandler={onAddCharge(CHARGE_TYPE.DELIVERY)}
                            displayMessage={rangeError}
                        />
                        <Grid container>
                            <Grid container item xs={12} mb={6}>
                                {deliveryCharges.map((chargeItem, index) =>
                                    <DeliveryChargeItem
                                        key={chargeItem._id ? chargeItem._id : index}
                                        index={index}
                                        chargeItem={chargeItem}
                                        handleChange={handleDeliveryChargeChange}
                                        themeMode={props.themeMode}
                                        theme_mode={theme_mode}
                                        drawerOpen={drawerOpen}
                                        onDelete={onDeleteCharge}
                                        error={error}
                                        maxRange={maxDeliveryRadius}
                                    />)}
                            </Grid>

                            <ChargeListHeader chargeTitle="Other Charges" styles={styles} theme_mode={theme_mode} addHandler={onAddCharge(CHARGE_TYPE.OTHER)} />
                            <Grid container item xs={12} justifyContent={"flex-end"} mb={2}>
                                {charges.map((chargeItem, index) => <OtherChargeItem
                                    key={chargeItem._id}
                                    chargeItem={chargeItem}
                                    index={index}
                                    handleChange={handleValueChange}
                                    themeMode={props.themeMode}
                                    theme_mode={theme_mode}
                                    drawerOpen={drawerOpen}
                                    handleValueChange={handleValueChange}
                                    onDelete={onDeleteCharge}
                                    error={error}
                                />)}

                            </Grid>

                        </Grid>
                    </Grid>


                    <Grid container item xs={12} justifyContent={"flex-end"} mt={2}>
                        <Grid item xs={12} md={5}>
                            <Box className={styles?.["btn-main-View"]} mt={2} sx={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                <Button
                                    type="button"
                                    className={styles?.["cancel-btn"]}
                                    sx={styles1.cancelBtn}
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="button"
                                    disabled={saveBtnDisabled}
                                    className={styles?.["save-btn"]}
                                    sx={{
                                        ...styles1.saveBtn,
                                        backgroundColor: saveBtnDisabled
                                            ? theme_mode.btnDisabledBg
                                            : theme_mode.btnBg4,
                                    }}
                                    onClick={onSaveCharges}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <DiningConfirmAlert
                page={"Charges"}
                data={alertConfirmModalData}
                themeMode={props.themeMode}
                onClose={() => toggleConfirmModal(null)}
                onDelete={onConfirmDelete}
            />

            <AlertMsg
                msgAlert={msgAlert}
                onCloseAlertMsg={() =>
                    setMsgAlert({ open: false, message: "", msgType: "error" })
                }
            />
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
    };
};

const mapStateToProps = (state) => {
    return {
        restaurantId: state.userData.restaurantId,
        themeMode: state.userData.themeMode,
        drawerOpen: state.userData.drawerOpen,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChargesSettings);

const styles2 = (Theme) => ({
    saveBtn: {
        backgroundColor: Theme.btnBg4,
        color: Theme.btnText,
        width: '100%',
        ml: 1,
        "&:hover": {
            backgroundColor: `${Theme.btnBg4} !important`,
        },
    },
    cancelBtn: {
        backgroundColor: Theme.btnBg5,
        border: `2px solid ${Theme.btnBg5Border}`,
        color: Theme.text,
        width: '100%',
        mr: 1,
        "&:hover": {
            backgroundColor: `${Theme.btnBg4} !important`,
        },
    },
});

