import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import Loader from "../../components/Loader";

import { connect } from "react-redux";
import { emptyPinLogin, logOut } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

const Logout = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const page = location.pathname.split("/").pop();
    if (props.pinLogin || page === "pin") {
      if (props.pinUserId === "") {
        logout();
      } else {
        userCheckout();
      }
    } else {
      logout();
    }
  }, []);

  const userCheckout = async () => {
    props.emptyPinLogin();
    navigate("/login/pin");
  };

  const logout = () => {
    Api.logout().then((response) => {
      if (response.success) {
        props.logOut();
        navigate("/");
      }
    });
  };

  return <Loader height={{ height: window.innerHeight }} />;
};

const mapDispatchToProps = (dispatch) => {
  return {
    logOut: () => dispatch(logOut()),
    emptyPinLogin: () => dispatch(emptyPinLogin()),
  };
};

const mapStateToProps = (state) => {
  return {
    pinLogin: state.userData.pinLogin,
    pinUserId: state.userData.pinUserId,
    restaurantId: state.userData.restaurantId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
