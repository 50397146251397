export const UPDATE_USER_TOKEN = 'UPDATE_USER_TOKEN';
export const UPDATE_REFRESH_TOKEN = 'UPDATE_REFRESH_TOKEN';
export const UPDATE_RESTAURANT_ID = 'UPDATE_RESTAURANT_ID';
export const UPDATE_RESTAURANT_IMG = 'UPDATE_RESTAURANT_IMG';
export const UPDATE_DRAWER_OPEN = 'UPDATE_DRAWER_OPEN';
export const UPDATE_FCM_TOKEN = 'UPDATE_FCM_TOKEN';
export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_CREATE_ORDER_MODAL_VISIBLE = 'UPDATE_CREATE_ORDER_MODAL_VISIBLE';
export const UPDATE_ORDER_OR_TABLE_TYPE = 'UPDATE_ORDER_OR_TABLE_TYPE';
export const UPDATE_RESTAURANT_DETAIL = 'UPDATE_RESTAURANT_DETAIL';
export const UPDATE_TABLE_ORDER_LIST = 'UPDATE_TABLE_ORDER_LIST';
export const UPDATE_FLOOR_TABLE_LIST = 'UPDATE_FLOOR_TABLE_LIST';
export const UPDATE_DINING_TABLE_ORDER = 'UPDATE_DINING_TABLE_ORDER';
export const UPDATE_RESTAURANT_CATEGORY_MENU_LIST = 'UPDATE_RESTAURANT_CATEGORY_MENU_LIST';
export const UPDATE_RESTAURANT_CATEGORY_LIST = 'UPDATE_RESTAURANT_CATEGORY_LIST';
export const UPDATE_RESTAURANT_MENU_LIST = 'UPDATE_RESTAURANT_MENU_LIST';
export const UPDATE_RESTAURANT_COURSE_LIST = 'UPDATE_RESTAURANT_COURSE_LIST';
export const UPDATE_DINING_SELECTED_FLOOR = 'UPDATE_DINING_SELECTED_FLOOR';
export const UPDATE_PIN_LOGIN = 'UPDATE_PIN_LOGIN';
export const EMPTY_PIN_LOGIN = 'EMPTY_PIN_LOGIN';
export const UPDATE_USER_DETAIL = 'UPDATE_USER_DETAIL';
export const UPDATE_TABLE_ORDER_BILLING_LIST = 'UPDATE_TABLE_ORDER_BILLING_LIST';
export const UPDATE_TABLE_ORDER_COURSE_AWAY = 'UPDATE_TABLE_ORDER_COURSE_AWAY';
export const UPDATE_OFFLINE_SETTINGS = 'UPDATE_OFFLINE_SETTINGS';
export const UPDATE_ACTIVE_PRINTER = 'UPDATE_ACTIVE_PRINTER';
export const UPDATE_RESTAURANT_CUSTOMER_LIST = 'UPDATE_RESTAURANT_CUSTOMER_LIST';
export const UPDATE_LOGIN_LIST_DATA = 'UPDATE_LOGIN_LIST_DATA';
export const UPDATE_USER_ROLES_LIST = 'UPDATE_USER_ROLES_LIST';
export const LOG_OUT = 'LOG_OUT';

// Printers
export const UPDATE_PRINTERS_LIST = 'UPDATE_PRINTERS_LIST';