import React from 'react'
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import AntSwitch from "../components/Switch";
import styles from "../Printers.module.css";
import SettingSelectField from "../../../components/SettingSelectField";
import SettingTextField from "../../../components/SettingTextField";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete.svg";
import { GENERAL_CHARGES_FIELDS, ORDER_TYPE_OPTIONS, CHARGE_TYPE, UNIQUE_ERROR } from "./constants";

const ChargeItem = ({ chargeItem, index, handleChange, error, drawerOpen, theme_mode, themeMode, onDelete, ...props }) => {

    return (
        <Grid
            key={chargeItem._id}
            container
            spacing={2}
            sx={{
                color: theme_mode.tableRowText,
                fontFamily: "InterMedium",
                fontSize: 14,
                border: 0,
                borderRadius: '0.75rem',
                padding: '0.5rem',
                marginTop: '1rem',
                paddingBottom: '1rem',
                marginLeft: 0,
                "&:nth-of-type(odd)": {
                    backgroundColor: theme_mode.tableRow2,
                },
                "&:nth-of-type(even)": {
                    backgroundColor: theme_mode.tableRow,
                },
            }}
        >
            <Grid container item xs={12}>
                <Grid item xs={12} sm={drawerOpen ? 12 : 6} xl={3} sx={{ padding: '0.5rem', sm: { minWidth: '25rem', color: 'red', background: 'purple' } }}>
                    <SettingTextField
                        value={chargeItem.name}
                        setValue={(e) => handleChange(e, index, GENERAL_CHARGES_FIELDS.NAME, CHARGE_TYPE.OTHER)}
                        placeholder={""}
                        label={"Charge Name"}
                        required={true}
                        labelStyle={styles.labelStyle}
                        variant={"filled"}
                        error={error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.NAME}`]}
                    />
                </Grid>

                <Grid item xs={12} sm={drawerOpen ? 12 : 6} xl={3} sx={{ padding: '0.5rem', sm: { minWidth: '25rem' } }}>
                    <SettingTextField
                        value={chargeItem.value}
                        setValue={(e) => handleChange(e, index, GENERAL_CHARGES_FIELDS.VALUE, CHARGE_TYPE.OTHER)}
                        required={true}
                        label={"Amount"}
                        labelStyle={styles.labelStyle}
                        variant={"filled"}
                        error={error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.VALUE}`]}
                        inputTextStyle={{ autoComplete: "off" }}
                        textAlign={chargeItem.isPercentage ? "right" : "left"}
                        leftView={!chargeItem.isPercentage &&
                            <Box sx={{ color: themeMode === "dark" ? "#ffffff" : "#000000" }}>£</Box>
                        }
                        rightView={chargeItem.isPercentage && <InputAdornment position="end" >
                            <Box sx={{ color: themeMode === "dark" ? "#ffffff" : "#000000" }}>%</Box>
                        </InputAdornment >}
                    />
                </Grid>

                <Grid item xs={12} sm={drawerOpen ? 12 : 6} xl={3} sx={{ padding: '0.5rem', sm: { minWidth: '25rem' } }}>
                    <SettingSelectField
                        page={"charges"}
                        value={chargeItem.orderType}
                        setValue={(val) => { handleChange(val, index, GENERAL_CHARGES_FIELDS.ORDER_TYPE, CHARGE_TYPE.OTHER) }}
                        editable={true}
                        placeholder={"Select Order type"}
                        required={true}
                        variant={"filled"}
                        label={"Category"}
                        error={error[`${CHARGE_TYPE.OTHER}_${index}_${GENERAL_CHARGES_FIELDS.ORDER_TYPE}`]}
                        menuList={ORDER_TYPE_OPTIONS}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    xl={3}
                    sx={{
                        padding: '0.5rem',
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        gap: '1rem',
                        alignItems: { xs: 'flex-start', md: 'center' },
                        justifyContent: { xs: 'flex-start', md: 'center' },
                        '& .MuiFormGroup-root': {
                            minWidth: 'fit-content'
                        },
                        sm: { minWidth: '25rem' }
                    }}
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <AntSwitch
                                    checked={chargeItem.isPercentage}
                                    onChange={(e) => handleChange(e, index, GENERAL_CHARGES_FIELDS.PERCENTAGE)}
                                    inputProps={{ "aria-label": "ant design" }}
                                    theme_mode={themeMode}
                                />
                            }
                            sx={{
                                color: themeMode === "dark" ? '#ffffff' : '#000000',
                                margin: 0,
                                '& .MuiTypography-root': {
                                    marginLeft: '0.5rem',
                                    whiteSpace: 'nowrap' // Prevents label from wrapping
                                }
                            }}
                            label="Set as Percentage"
                        />
                    </FormGroup>

                    <FormGroup>
                        <FormControlLabel
                            control={
                                <AntSwitch
                                    checked={chargeItem.isActive}
                                    onChange={(e) => handleChange(e, index, GENERAL_CHARGES_FIELDS.ACTIVE)}
                                    inputProps={{ "aria-label": "ant design" }}
                                    theme_mode={themeMode}
                                />
                            }
                            sx={{
                                color: themeMode === "dark" ? '#ffffff' : '#000000',
                                margin: 0,
                                '& .MuiTypography-root': {
                                    marginLeft: '0.5rem',
                                    whiteSpace: 'nowrap'
                                }
                            }}
                            label="Is Active"
                        />
                    </FormGroup>

                    <Box
                        className={styles.actionBox}
                        sx={{
                            alignSelf: { xs: 'flex-start', md: 'center' },
                            path: {
                                stroke: theme_mode.iconBg2,
                            }
                        }}
                    >
                        <DeleteIcon
                            height={20}
                            width={20}
                            style={{ cursor: "pointer", color: 'red' }}
                            onClick={() => onDelete({ index, type: CHARGE_TYPE.OTHER })}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={{ color: theme_mode.redText, fontSize: 10, fontFamily: "InterMedium" }}>{error[`${CHARGE_TYPE.OTHER}_${index}_${UNIQUE_ERROR}`]}</Typography>
            </Grid>
        </Grid >
    )
}

export default ChargeItem
