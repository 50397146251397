import React, { useEffect, useReducer, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';

import styles from "./TakeAwaySettings.module.css";

import { connect } from "react-redux";
import { updateRestaurantDetail } from "../../redux/actions/userDataActions";

import * as Api from "../../api";

import { ReactComponent as DeleteIcon } from "../../assets/images/delete2.svg";
import Container from "../../components/Container";
import Loader from "../../components/Loader";
import SettingTextField from "../../components/SettingTextField";
import AlertMsg from "../../components/AlertMsg";
import SettingTimePicker from "../../components/SettingTimePicker";
import SwitchInput from "./SwitchInput";
import AddSubMenuBtn from "../../components/AddSubMenuBtn";
import ImageColorComponent, { MODES } from "./TakeawayComponents/ImageColorComponent";
import { isColorHexValid } from "../../utils/testColorHex";

import { ReactComponent as BackBtnIcon } from "../../assets/images/back_btn.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/rest_info.svg";
import { ReactComponent as UpArrow } from "../../assets/images/up_white_arrow.svg";
import { ReactComponent as DownArrow } from "../../assets/images/down_white_arrow.svg";


import { THEME_MODE } from "../../constants/Theme";

const PAGE_CONSTANTS = {
  header_pickup: "header_pickup",
  header_delivery: "header_delivery",
  footer_pickup: "footer_pickup",
  footer_delivery: "footer_delivery",
}

const EmailTemplateHeader = ({ title, onClick, showSection, theme_mode, styles }) => {
  return (
    <Grid
      container
      item
      xs={12}
      onClick={onClick}
      sx={{
        backgroundColor: theme_mode.inputBg, cursor: "pointer", borderRadius: '12px',
        padding: '0.75rem', marginTop: '2rem', marginLeft: '16px'
      }}
    >
      <Grid item xs={11} sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <label style={{ ...styles.labelStyle, fontSize: '1.125rem' }}>{title}</label>
      </Grid>
      <Grid item xs={1} sx={{ textAlign: "right" }}>
        {showSection ? <DownArrow width={33} height={33} /> : <UpArrow width={33} height={33} />}
      </Grid>
    </Grid>)
}

const validateStartAndEndTime = (startTime, endTime) => {
  const startTimeInMinutes = (startTime.getHours() * 60) + startTime.getMinutes();
  const endTimeInMinutes = (endTime.getHours() * 60) + endTime.getMinutes();

  return startTimeInMinutes < endTimeInMinutes;
}

const TakeAwaySettings = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const styles1 = styles2(theme_mode);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [onlineOrderVal, setOnlineOrderVal] = useState(false);
  const [deliveryWindowStartTime, setDeliveryWindowStartTime] = useState("");
  const [deliveryWindowEndTime, setDeliveryWindowEndTime] = useState("");
  const [takeAwayWindowStartTime, setTakeAwayWindowStartTime] = useState("");
  const [takeAwayWindowEndTime, setTakeAwayWindowEndTime] = useState("");
  const [minCollectionOrderAmount, setMinCollectionOrderAmount] = useState("");
  const [minDeliveryOrderAmount, setMinDeliveryOrderAmount] = useState("");
  const [isDeliveryEnabledVal, setIsDeliveryEnabledVal] = useState(false);
  const [isTakeAwayEnabledVal, setIsTakeAwayEnabledVal] = useState(false);
  const [busyModeVal, setBusyModeVal] = useState(false);
  const [busyModeTimeVal, setBusyModeTimeVal] = useState(0);
  const [defaultBusyModeTimeVal, setDefaultBusyModeTimeVal] = useState("");
  const [deliveryRadiusVal, setDeliveryRadiusVal] = useState("");

  const [showPickupSection, setShowPickupSection] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);
  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(null);
  const [headerTitle, setHeaderTitle] = useState("");
  const [headerTitleColor, setHeaderTitleColor] = useState(null);
  const [headerSubTitle, setHeaderSubTitle] = useState("");
  const [headerSubTitleColor, setHeaderSubTitleColor] = useState(null);
  const [helpText, setHelpText] = useState("");
  const [collectionInfoHeading, setCollectionInfoHeading] = useState("");
  const [collectionInfoText, setCollectionInfoText] = useState("");
  const [footerImage, setFooterImage] = useState(null);
  const [footerBackgroundColor, setFooterBackgroundColor] = useState(null);
  const [footerTitle, setFooterTitle] = useState("");
  const [footerTitleColor, setFooterTitleColor] = useState(null);
  const [footerSubTitle, setFooterSubTitle] = useState("");
  const [footerSubTitleColor, setFooterSubTitleColor] = useState(null);
  const [packageField, setPackageField] = useState("");

  const [showDeliverySection, setShowDeliverySection] = useState(false);
  const [headerImage_delivery, setHeaderImage_delivery] = useState(null);
  const [headerBackgroundColor_delivery, setHeaderBackgroundColor_delivery] = useState(null);
  const [headerTitle_delivery, setHeaderTitle_delivery] = useState("");
  const [headerTitleColor_delivery, setHeaderTitleColor_delivery] = useState(null);
  const [headerSubTitle_delivery, setHeaderSubTitle_delivery] = useState("");
  const [headerSubTitleColor_delivery, setHeaderSubTitleColor_delivery] = useState(null);
  const [helpText_delivery, setHelpText_delivery] = useState("");
  const [collectionInfoHeading_delivery, setCollectionInfoHeading_delivery] = useState("");
  const [collectionInfoText_delivery, setCollectionInfoText_delivery] = useState("");
  const [footerImage_delivery, setFooterImage_delivery] = useState(null);
  const [footerBackgroundColor_delivery, setFooterBackgroundColor_delivery] = useState(null);
  const [footerTitle_delivery, setFooterTitle_delivery] = useState("");
  const [footerTitleColor_delivery, setFooterTitleColor_delivery] = useState(null);
  const [footerSubTitle_delivery, setFooterSubTitle_delivery] = useState("");
  const [footerSubTitleColor_delivery, setFooterSubTitleColor_delivery] = useState(null);
  const [packageField_delivery, setPackageField_delivery] = useState("");

  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });
  const [error, setError] = useState(null);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);

  useEffect(() => {
    getRestaurantData();
  }, []);

  useEffect(() => {
    let {
      onlineOrder = false,
      busyMode = false,
      busyModeTime = 0,
      defaultBusyModeTime = "",
      minimumCollectionOrderAmount = "",
      minimumDeliveryOrderAmount = "",
      deliveryRadius = "",
      deliveryWindow = [],
      takeAwayWindow = [],
      isDeliveryEnabled = false,
      isTakeAwayEnabled = false,
      // Populating for compatibility with existing data
      emailTemplate = []
    } = props.restaurantDetail || {};

    const deliveryWindowData = onSetDeliveryTakeAwayWindow(
      deliveryWindowStartTime,
      deliveryWindowEndTime
    );
    const takeAwayWindowData = onSetDeliveryTakeAwayWindow(
      takeAwayWindowStartTime,
      takeAwayWindowEndTime
    );

    const pickupEmailTemplateData = {
      headerImage: headerImage,
      headerBgColor: headerBackgroundColor,
      headerTitle: headerTitle,
      headerTitleColor: headerTitleColor,
      headerSubTitle: headerSubTitle,
      headerSubTitleColor: headerSubTitleColor,
      helpText: helpText,
      collectionInformationHeading: collectionInfoHeading,
      collectionInformationText: collectionInfoText,
      footerImage: footerImage,
      footerBgColor: footerBackgroundColor,
      footerTitle: footerTitle,
      footerTitleColor: footerTitleColor,
      footerSubTitle: footerSubTitle,
      footerSubTitleColor: footerSubTitleColor,
      // packageField: 
    }

    const deliveryEmailTemplateData = {
      headerImage: headerImage_delivery,
      headerBgColor: headerBackgroundColor_delivery,
      headerTitle: headerTitle_delivery,
      headerTitleColor: headerTitleColor_delivery,
      headerSubTitle: headerSubTitle_delivery,
      headerSubTitleColor: headerSubTitleColor_delivery,
      helpText: helpText_delivery,
      collectionInformationHeading: collectionInfoHeading_delivery,
      collectionInformationText: collectionInfoText_delivery,
      footerImage: footerImage_delivery,
      footerBgColor: footerBackgroundColor_delivery,
      footerTitle: footerTitle_delivery,
      footerTitleColor: footerTitleColor_delivery,
      footerSubTitle: footerSubTitle_delivery,
      footerSubTitleColor: footerSubTitleColor_delivery,
      // packageField: 
    }

    const data = {
      onlineOrder: onlineOrderVal,
      isDeliveryEnabled: isDeliveryEnabledVal,
      isTakeAwayEnabled: isTakeAwayEnabledVal,
      busyMode: busyModeVal,
      busyModeTime: Number(busyModeTimeVal),
      defaultBusyModeTime: Number(defaultBusyModeTimeVal),
      minimumCollectionOrderAmount: Number(minCollectionOrderAmount),
      minimumDeliveryOrderAmount: Number(minDeliveryOrderAmount),
      deliveryRadius: Number(deliveryRadiusVal),
      deliveryWindow: deliveryWindowData,
      takeAwayWindow: takeAwayWindowData,
      emailTemplate: [{
        onlineOrderConfirmation: [pickupEmailTemplateData, deliveryEmailTemplateData]
      }]
    };

    if (deliveryWindow.length) {
      delete deliveryWindow?.[0]?._id;
    }

    if (takeAwayWindow.length) {
      delete takeAwayWindow?.[0]?._id;
    }

    const restData = {
      onlineOrder,
      isDeliveryEnabled,
      isTakeAwayEnabled,
      busyMode,
      busyModeTime,
      defaultBusyModeTime,
      minimumCollectionOrderAmount,
      minimumDeliveryOrderAmount,
      deliveryRadius,
      deliveryWindow,
      takeAwayWindow,
      emailTemplate: emailTemplate.length ? emailTemplate : [{
        onlineOrderConfirmation: [
          {
            headerImage: null,
            headerBgColor: null,
            headerTitle: "",
            headerTitleColor: null,
            headerSubTitle: "",
            headerSubTitleColor: null,
            helpText: "",
            collectionInformationHeading: "",
            collectionInformationText: "",
            footerImage: null,
            footerBgColor: null,
            footerTitle: "",
            footerTitleColor: null,
            footerSubTitle: "",
            footerSubTitleColor: null
          },
          {
            headerImage: null,
            headerBgColor: null,
            headerTitle: "",
            headerTitleColor: null,
            headerSubTitle: "",
            headerSubTitleColor: null,
            helpText: "",
            collectionInformationHeading: "",
            collectionInformationText: "",
            footerImage: null,
            footerBgColor: null,
            footerTitle: "",
            footerTitleColor: null,
            footerSubTitle: "",
            footerSubTitleColor: null
          }
        ]
      }]
    };

    if (JSON.stringify(data) !== JSON.stringify(restData)) {
      setSaveBtnDisabled(false);
    } else {
      setSaveBtnDisabled(true);
    }
  }, [
    defaultBusyModeTimeVal,
    minDeliveryOrderAmount,
    minCollectionOrderAmount,
    deliveryWindowEndTime,
    deliveryWindowStartTime,
    takeAwayWindowEndTime,
    takeAwayWindowStartTime,
    isDeliveryEnabledVal,
    isTakeAwayEnabledVal,
    onlineOrderVal,
    busyModeVal,
    busyModeTimeVal,
    deliveryRadiusVal,
    // Email Template Variables
    headerImage,
    headerBackgroundColor,
    headerTitle,
    headerTitleColor,
    headerSubTitle,
    headerSubTitleColor,
    helpText,
    collectionInfoHeading,
    collectionInfoText,
    footerImage,
    footerBackgroundColor,
    footerTitle,
    footerTitleColor,
    footerSubTitle,
    footerSubTitleColor,
    packageField,
    // packageField
    headerImage_delivery,
    headerBackgroundColor_delivery,
    headerTitle_delivery,
    headerTitleColor_delivery,
    headerSubTitle_delivery,
    headerSubTitleColor_delivery,
    helpText_delivery,
    collectionInfoHeading_delivery,
    collectionInfoText_delivery,
    footerImage_delivery,
    footerBackgroundColor_delivery,
    footerTitle_delivery,
    footerTitleColor_delivery,
    footerSubTitle_delivery,
    footerSubTitleColor_delivery,
    packageField_delivery,
  ]);

  const getRestaurantData = async () => {
    setLoading(true);
    const response = await Api.getRestaurantData(props.restaurantId);
    if (response.success) {
      const data = response.data;
      onUpdateSetVariables(data);
      props.updateRestaurantDetail(data);
    } else {
      setMsgAlert({ open: true, message: response.msg, msgType: "error" });
    }
    setLoading(false);
  };

  const onSaveGeneralSettings = () => {
    let err = null;

    if (minDeliveryOrderAmount && /[^0-9.]/g.test(minDeliveryOrderAmount)) {
      err = {
        ...err,
        minimumDeliveryOrderAmount: "Invalid Minimum Delivery Order Amount",
      };
    }

    if (deliveryWindowStartTime && !deliveryWindowEndTime) {
      err = {
        ...err,
        deliveryWindowEndTime: "Delivery window end time is required",
      };
    }

    if (deliveryWindowEndTime && !deliveryWindowStartTime) {
      err = {
        ...err,
        deliveryWindowStartTime: "Delivery window start time is required",
      };
    }

    if (deliveryWindowStartTime && deliveryWindowEndTime && !validateStartAndEndTime(deliveryWindowStartTime, deliveryWindowEndTime)) {
      err = {
        ...err,
        deliveryWindowStartTime: "Delivery window start time should be less than end time",
        deliveryWindowEndTime: "Delivery window end time should be greater than start time",
      }
    }

    if (takeAwayWindowStartTime && !takeAwayWindowStartTime) {
      err = {
        ...err,
        takeAwayWindowEndTime: "Take Away window end time is required",
      };
    }

    if (takeAwayWindowEndTime && !takeAwayWindowStartTime) {
      err = {
        ...err,
        takeAwayWindowStartTime: "Take Away window start time is required",
      };
    }

    if (takeAwayWindowStartTime && takeAwayWindowEndTime && !validateStartAndEndTime(takeAwayWindowStartTime, takeAwayWindowEndTime)) {
      err = {
        ...err,
        takeAwayWindowStartTime: "Takeaway window start time should be less than end time",
        takeAwayWindowEndTime: "Takeaway window end time should be greater than start time",
      };
    }

    if (headerBackgroundColor && !isColorHexValid(headerBackgroundColor)) {
      err = {
        ...err,
        [PAGE_CONSTANTS.header_pickup]: "A valid color is required",
      }
    }

    // if (!headerTitle) {
    //   err = {
    //     ...err,
    //     headerTitle: "Header Title is required",
    //   }
    // }
    if (headerTitleColor && !isColorHexValid(headerTitleColor)) {
      err = {
        ...err,
        headerTitleColor: "A valid color is required",
      }
    }
    // if (!headerSubTitle) {
    //   err = {
    //     ...err,
    //     headerSubTitle: "Header Subtitle is required",
    //   }
    // }
    if (headerSubTitleColor && !isColorHexValid(headerSubTitleColor)) {
      err = {
        ...err,
        headerSubTitleColor: "A valid color is required",
      }
    }
    // if (!helpText) {
    //   err = {
    //     ...err,
    //     helpText: "Help Text is required",
    //   }
    // }
    // if (!collectionInfoHeading) {
    //   err = {
    //     ...err,
    //     collectionInfoHeading: "Collection Info Heading is required",
    //   }
    // }
    // if (!collectionInfoText) {
    //   err = {
    //     ...err,
    //     collectionInfoText: "Collection Info Text is required",
    //   }
    // }
    if (footerBackgroundColor && !isColorHexValid(footerBackgroundColor)) {
      err = {
        ...err,
        [PAGE_CONSTANTS.footer_pickup]: "A valid color is required",
      }
    }
    // if (!footerTitle) {
    //   err = {
    //     ...err,
    //     footerTitle: "Footer Title is required",
    //   }
    // }
    if (footerTitleColor && !isColorHexValid(footerTitleColor)) {
      err = {
        ...err,
        footerTitleColor: "A valid color is required",
      }
    }
    // if (!footerSubTitle) {
    //   err = {
    //     ...err,
    //     footerSubTitle: "Footer Subtitle is required",
    //   }
    // }
    if (footerSubTitleColor && !isColorHexValid(footerSubTitleColor)) {
      err = {
        ...err,
        footerSubTitleColor: "A valid color is required",
      }
    }

    if (headerBackgroundColor_delivery && !isColorHexValid(headerBackgroundColor_delivery)) {
      err = {
        ...err,
        [PAGE_CONSTANTS.header_delivery]: "A valid color is required",
      }
    }
    // if (!headerTitle_delivery) {
    //   err = {
    //     ...err,
    //     headerTitle_delivery: "Header Title is required",
    //   }
    // }
    if (headerTitleColor_delivery && !isColorHexValid(headerTitleColor_delivery)) {
      err = {
        ...err,
        headerTitleColor_delivery: "A valid color is required",
      }
    }
    // if (!headerSubTitle_delivery) {
    //   err = {
    //     ...err,
    //     headerSubTitle_delivery: "Header Subtitle is required",
    //   }
    // }
    if (headerSubTitleColor_delivery && !isColorHexValid(headerSubTitleColor_delivery)) {
      err = {
        ...err,
        headerSubTitleColor_delivery: "A valid color is required",
      }
    }
    // if (!helpText_delivery) {
    //   err = {
    //     ...err,
    //     helpText_delivery: "Help Text is required",
    //   }
    // }
    // if (!collectionInfoHeading_delivery) {
    //   err = {
    //     ...err,
    //     collectionInfoHeading_delivery: "Collection Info Heading is required",
    //   }
    // }
    // if (!collectionInfoText_delivery) {
    //   err = {
    //     ...err,
    //     collectionInfoText_delivery: "Collection Info Text is required",
    //   }
    // }
    if (footerBackgroundColor_delivery && !isColorHexValid(footerBackgroundColor_delivery)) {
      err = {
        ...err,
        [PAGE_CONSTANTS.footer_delivery]: "A valid color is required",
      }
    }
    // if (!footerTitle_delivery) {
    //   err = {
    //     ...err,
    //     footerTitle_delivery: "Footer Title is required",
    //   }
    // }
    if (footerTitleColor_delivery && !isColorHexValid(footerTitleColor_delivery)) {
      err = {
        ...err,
        footerTitleColor_delivery: "A valid color is required",
      }
    }
    // if (!footerSubTitle_delivery) {
    //   err = {
    //     ...err,
    //     footerSubTitle_delivery: "Footer Subtitle is required",
    //   }
    // }
    if (footerSubTitleColor_delivery && !isColorHexValid(footerSubTitleColor_delivery)) {
      err = {
        ...err,
        footerSubTitleColor_delivery: "A valid color is required",
      }
    }

    setError(err);
    if (err != null) {
      return;
    }

    let data = {
      defaultBusyModeTime: defaultBusyModeTimeVal ? defaultBusyModeTimeVal : 0,
      minimumCollectionOrderAmount: minCollectionOrderAmount
        ? minCollectionOrderAmount
        : 0,
      minimumDeliveryOrderAmount: minDeliveryOrderAmount
        ? minDeliveryOrderAmount
        : 0,
      onlineOrder: onlineOrderVal,
      busyMode: busyModeVal,
      busyModeTime: busyModeTimeVal ? busyModeTimeVal : 0,
      deliveryRadius: deliveryRadiusVal ? deliveryRadiusVal : 0,
      isDeliveryEnabled: isDeliveryEnabledVal,
      isTakeAwayEnabled: isTakeAwayEnabledVal,
      deliveryWindow: onSetDeliveryTakeAwayWindow(
        deliveryWindowStartTime,
        deliveryWindowEndTime
      ),
      takeAwayWindow: onSetDeliveryTakeAwayWindow(
        takeAwayWindowStartTime,
        takeAwayWindowEndTime
      ),
      emailTemplate: [
        {
          onlineOrderConfirmation: [
            {
              headerImage: headerImage?.link ?? null,
              headerBgColor: headerBackgroundColor,
              headerTitleColor,
              headerTitle,
              headerSubTitle,
              headerSubTitleColor,
              helpText,
              collectionInformationHeading: collectionInfoHeading,
              collectionInformationText: collectionInfoText,
              footerImage: footerImage?.link ?? null,
              footerBgColor: footerBackgroundColor,
              footerTitle,
              footerTitleColor,
              footerSubTitle,
              footerSubTitleColor
            },
            {
              headerImage: headerImage_delivery?.link ?? null,
              headerBgColor: headerBackgroundColor_delivery,
              headerTitleColor: headerTitleColor_delivery,
              headerTitle: headerTitle_delivery,
              headerSubTitle: headerSubTitle_delivery,
              headerSubTitleColor: headerSubTitleColor_delivery,
              helpText: helpText_delivery,
              collectionInformationHeading: collectionInfoHeading_delivery,
              collectionInformationText: collectionInfoText_delivery,
              footerImage: footerImage_delivery?.link ?? null,
              footerBgColor: footerBackgroundColor_delivery,
              footerTitle: footerTitle_delivery,
              footerTitleColor: footerTitleColor_delivery,
              footerSubTitle: footerSubTitle_delivery,
              footerSubTitleColor: footerSubTitleColor_delivery
            },
          ]
        }
      ]
    };
    let datas = JSON.stringify(data);

    setLoading(true);
    Api.updateRestaurant(datas, props.restaurantId).then((response) => {
      if (response.success) {
        props.updateRestaurantDetail({
          ...props.restaurantDetail,
          ...data,
        });
        setSaveBtnDisabled(true);
        setMsgAlert({ open: true, message: response.msg, msgType: "success" });
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setLoading(false);
    });
  };

  const onUpdateSetVariables = (data) => {
    const {
      onlineOrder = false,
      busyMode = false,
      busyModeTime = 0,
      defaultBusyModeTime = "",
      minimumCollectionOrderAmount = "",
      minimumDeliveryOrderAmount = "",
      deliveryRadius = "",
      deliveryWindow = [],
      takeAwayWindow = [],
      isDeliveryEnabled = false,
      isTakeAwayEnabled = false,
      emailTemplate = []
    } = data || {};

    setOnlineOrderVal(onlineOrder);
    setIsDeliveryEnabledVal(isDeliveryEnabled);
    setIsTakeAwayEnabledVal(isTakeAwayEnabled);
    setBusyModeVal(busyMode);
    setMinCollectionOrderAmount(minimumCollectionOrderAmount);
    setMinDeliveryOrderAmount(minimumDeliveryOrderAmount);
    setBusyModeTimeVal(busyModeTime);
    setDefaultBusyModeTimeVal(defaultBusyModeTime);
    setDeliveryRadiusVal(deliveryRadius);
    setDeliveryWindowStartTime(
      onSetTimeFormat(deliveryWindow?.[0]?.startTime ?? "")
    );
    setDeliveryWindowEndTime(
      onSetTimeFormat(deliveryWindow?.[0]?.endTime ?? "")
    );
    setTakeAwayWindowStartTime(
      onSetTimeFormat(takeAwayWindow?.[0]?.startTime ?? "")
    );
    setTakeAwayWindowEndTime(
      onSetTimeFormat(takeAwayWindow?.[0]?.endTime ?? "")
    );

    const pickupEmailTemplateFields = emailTemplate.length ? emailTemplate[0].onlineOrderConfirmation[0] ?? {} : {};

    if (pickupEmailTemplateFields.headerImage) setHeaderImage({ link: pickupEmailTemplateFields.headerImage });
    if (pickupEmailTemplateFields.headerBgColor) setHeaderBackgroundColor(pickupEmailTemplateFields.headerBgColor);
    setHeaderTitle(pickupEmailTemplateFields.headerTitle ?? "");
    setHeaderTitleColor(pickupEmailTemplateFields.headerTitleColor ?? null);
    setHeaderSubTitle(pickupEmailTemplateFields.headerSubTitle ?? "");
    setHeaderSubTitleColor(pickupEmailTemplateFields.headerSubTitleColor ?? null);
    setHelpText(pickupEmailTemplateFields.helpText ?? "");
    setCollectionInfoText(pickupEmailTemplateFields.collectionInformationText);
    setCollectionInfoHeading(pickupEmailTemplateFields.collectionInformationHeading);
    if (pickupEmailTemplateFields.footerImage) setFooterImage({ link: pickupEmailTemplateFields.footerImage });
    if (pickupEmailTemplateFields.footerBgColor) setFooterBackgroundColor(pickupEmailTemplateFields.footerBgColor);
    setFooterTitle(pickupEmailTemplateFields.footerTitle ?? "");
    setFooterTitleColor(pickupEmailTemplateFields.footerTitleColor ?? null);
    setFooterSubTitle(pickupEmailTemplateFields.footerSubTitle ?? "");
    setFooterSubTitleColor(pickupEmailTemplateFields.footerSubTitleColor ?? null);

    const deliveryEmailTemplateFields = emailTemplate.length ? emailTemplate[0].onlineOrderConfirmation[1] ?? {} : {};

    if (deliveryEmailTemplateFields.headerImage) setHeaderImage_delivery({ link: deliveryEmailTemplateFields.headerImage });
    if (deliveryEmailTemplateFields.headerBgColor) setHeaderBackgroundColor_delivery(deliveryEmailTemplateFields.headerBgColor);
    setHeaderTitle_delivery(deliveryEmailTemplateFields.headerTitle ?? "");
    setHeaderTitleColor_delivery(deliveryEmailTemplateFields.headerTitleColor ?? null);
    setHeaderSubTitle_delivery(deliveryEmailTemplateFields.headerSubTitle ?? "");
    setHeaderSubTitleColor_delivery(deliveryEmailTemplateFields.headerSubTitleColor ?? null);
    setHelpText_delivery(deliveryEmailTemplateFields.helpText ?? "");
    setCollectionInfoText_delivery(deliveryEmailTemplateFields.collectionInformationText);
    setCollectionInfoHeading_delivery(deliveryEmailTemplateFields.collectionInformationHeading);
    if (deliveryEmailTemplateFields.footerImage) setFooterImage_delivery({ link: deliveryEmailTemplateFields.footerImage });
    if (deliveryEmailTemplateFields.footerBgColor) setFooterBackgroundColor_delivery(deliveryEmailTemplateFields.footerBgColor);
    setFooterTitle_delivery(deliveryEmailTemplateFields.footerTitle ?? "");
    setFooterTitleColor_delivery(deliveryEmailTemplateFields.footerTitleColor ?? null);
    setFooterSubTitle_delivery(deliveryEmailTemplateFields.footerSubTitle ?? "");
    setFooterSubTitleColor_delivery(deliveryEmailTemplateFields.footerSubTitleColor ?? null);
  };

  const onSetTimeFormat = (val) => {
    return val
      ? new Date(moment(val, "H:mm").format("YYYY-MM-DD HH:mm:ss"))
      : "";
  };

  const onSetDeliveryTakeAwayWindow = (startTime, endTime) => {
    return startTime && endTime
      ? [
        {
          startTime: moment(startTime).format("H:mm"),
          endTime: moment(endTime).format("H:mm"),
        },
      ]
      : [];
  };

  const onCancel = () => {
    onUpdateSetVariables(props.restaurantDetail);
  };

  const handlerOnSwitch = (type, value) => {
    if (type === "isDeliveryEnabled") {
      setIsDeliveryEnabledVal(value);
    } else if (type === "isTakeAwayEnabled") {
      setIsTakeAwayEnabledVal(value);
    } else if (type === "busyMode") {
      setBusyModeVal(value);
    } else if (type === "onlineOrder") {
      setOnlineOrderVal(value);
    }
  };

  const handlerOnChange = (type, value) => {
    if (type === "defaultBusyModeTime") {
      setDefaultBusyModeTimeVal(value.replace(/[^0-9.]/g, ""));
    } else if (type === "minimumCollectionOrderAmount") {
      setMinCollectionOrderAmount(value.replace(/[^0-9.]/g, ""));
    } else if (type === "minimumDeliveryOrderAmount") {
      setMinDeliveryOrderAmount(value.replace(/[^0-9.]/g, ""));
    } else if (type === "deliveryRadius") {
      setDeliveryRadiusVal(value.replace(/[^0-9.]/g, ""));
    } else if (type === "deliveryWindowStartTime") {
      setDeliveryWindowStartTime(value ? value : "");
    } else if (type === "deliveryWindowEndTime") {
      setDeliveryWindowEndTime(value ? value : "");
    } else if (type === "takeAwayWindowStartTime") {
      setTakeAwayWindowStartTime(value ? value : "");
    } else if (type === "takeAwayWindowEndTime") {
      setTakeAwayWindowEndTime(value ? value : "");
    }
  };

  const uploadFile = (id, file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);
    Api.uploadImageFile(formData).then((response) => {
      if (response.success) {
        if (id === PAGE_CONSTANTS.header_pickup) {
          const newImageDetails = headerImage ? structuredClone(headerImage) : {};
          newImageDetails.link = response.data.location;
          newImageDetails.uploaded = true;
          setHeaderBackgroundColor(null);
          setHeaderImage(newImageDetails);
        }
        if (id === PAGE_CONSTANTS.footer_pickup) {
          const newImageDetails = footerImage ? structuredClone(footerImage) : {};
          newImageDetails.link = response.data.location;
          newImageDetails.uploaded = true;
          setFooterBackgroundColor(null);
          setFooterImage(newImageDetails);
        }

        if (id === PAGE_CONSTANTS.header_delivery) {
          const newImageDetails = headerImage_delivery ? structuredClone(headerImage_delivery) : {};
          newImageDetails.link = response.data.location;
          newImageDetails.uploaded = true;
          setHeaderBackgroundColor_delivery(null);
          setHeaderImage_delivery(newImageDetails);
        }
        if (id === PAGE_CONSTANTS.footer_delivery) {
          const newImageDetails = footerImage_delivery ? structuredClone(footerImage_delivery) : {};
          newImageDetails.link = response.data.location;
          newImageDetails.uploaded = true;
          setFooterBackgroundColor_delivery(null);
          setFooterImage_delivery(newImageDetails);
        }
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
        if (id === PAGE_CONSTANTS.header_pickup) setHeaderImage(null);
        if (id === PAGE_CONSTANTS.footer_pickup) setFooterImage(null);
        if (id === PAGE_CONSTANTS.header_delivery) setHeaderImage_delivery(null);
        if (id === PAGE_CONSTANTS.footer_delivery) setFooterImage_delivery(null);
      }
    }).finally(() => { setLoading(false) });
  };


  const handleImageColorFieldChange = (id, mode, val) => {
    if (id === PAGE_CONSTANTS.header_pickup) {
      if (mode === MODES.IMAGE) {
        setHeaderImage(val);
        setHeaderBackgroundColor(null);
        if (val) uploadFile(id, val);
      } else {
        setHeaderImage(null);
        setHeaderBackgroundColor(val);
      }
    }

    if (id === PAGE_CONSTANTS.footer_pickup) {
      if (mode === MODES.IMAGE) {
        setFooterImage(val);
        setFooterBackgroundColor(null);
        if (val) uploadFile(id, val);
      } else {
        setFooterImage(null);
        setFooterBackgroundColor(val);
      }
    }

    if (id === PAGE_CONSTANTS.header_delivery) {
      if (mode === MODES.IMAGE) {
        setHeaderImage_delivery(val);
        setHeaderBackgroundColor_delivery(null);
        if (val) uploadFile(id, val);
      } else {
        setHeaderImage_delivery(null);
        setHeaderBackgroundColor_delivery(val);
      }
    }

    if (id === PAGE_CONSTANTS.footer_delivery) {
      if (mode === MODES.IMAGE) {
        setFooterImage_delivery(val);
        setFooterBackgroundColor_delivery(null);
        if (val) uploadFile(id, val);
      } else {
        setFooterImage_delivery(null);
        setFooterBackgroundColor_delivery(val);
      }
    }
  }

  const onSetBusyModeTime = (type) => {
    let val = busyModeTimeVal;
    if (type === "+") {
      val += 5;
      setBusyModeTimeVal(val);
    } else {
      val -= 5;
      if (val >= 0) {
        setBusyModeTimeVal(val);
      }
    }
  };

  const ToggleSwitchLabel = ({ label, info }) => {
    return (
      <Typography className={styles?.["toggle-label"]} sx={styles1.labelStyle}>
        {label}
        {info ? (
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  marginTop: "0px !important",
                  marginBottom: "0px !important",
                  backgroundColor: "#EEE",
                  color: "#000",
                },
              },
            }}
            title={info}
            placement="top"
          >
            <InfoIcon
              height={15}
              width={15}
              style={{ cursor: "pointer", marginLeft: 4 }}
            />
          </Tooltip>
        ) : null}
      </Typography>
    );
  };

  return (
    <Container page={"settings"} p={1.5}>
      {loading ? (
        <Loader />
      ) : (
        <Grid
          item
          xs={12}
          sx={{
            p: 1.5,
            backgroundColor: theme_mode.card3,
            borderRadius: 5,
          }}
        >
          <Grid container item md={12} xs={12} spacing={2}>
            <Grid item md={12} xs={12}>
              <Box
                onClick={() => navigate("/settings")}
                sx={{ cursor: "pointer" }}
              >
                <BackBtnIcon width={33} height={33} />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              mb={2}
            >
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel
                  label={"Online Order"}
                  info={"Toggle option for user to place order online"}
                />
                <SwitchInput
                  themeMode={props.themeMode}
                  checked={onlineOrderVal}
                  onChange={(val) => handlerOnSwitch("onlineOrder", val)}
                />
              </Box>
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel label={"Delivery"} info={""} />
                <SwitchInput
                  themeMode={props.themeMode}
                  checked={isDeliveryEnabledVal}
                  onChange={(val) => handlerOnSwitch("isDeliveryEnabled", val)}
                />
              </Box>
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel label={"TakeAway"} info={""} />
                <SwitchInput
                  themeMode={props.themeMode}
                  checked={isTakeAwayEnabledVal}
                  onChange={(val) => handlerOnSwitch("isTakeAwayEnabled", val)}
                />
              </Box>
              <Box className={styles?.["switch-input-view"]}>
                <ToggleSwitchLabel label={"Busy Mode"} info={""} />
                <SwitchInput
                  themeMode={props.themeMode}
                  checked={busyModeVal}
                  onChange={(val) => handlerOnSwitch("busyMode", val)}
                />
              </Box>
              {busyModeVal ? (
                <Box className={styles?.["switch-input-view"]}>
                  <ToggleSwitchLabel
                    label={"Busy Mode Time (in mins)"}
                    info={""}
                  />
                  <AddSubMenuBtn
                    themeMode={props.themeMode}
                    count={busyModeTimeVal}
                    onAddSub={onSetBusyModeTime}
                  />
                </Box>
              ) : null}
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={defaultBusyModeTimeVal}
                  setValue={(val) =>
                    handlerOnChange("defaultBusyModeTime", val)
                  }
                  placeholder={""}
                  required={true}
                  label={"Default Busy Mode Time"}
                  labelStyle={styles1.labelStyle}
                  variant={"filled"}
                  error={error?.defaultBusyModeTime}
                />
              </Grid>
              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={deliveryRadiusVal}
                  setValue={(val) => handlerOnChange("deliveryRadius", val)}
                  placeholder={""}
                  required={true}
                  label={"Delivery Radius"}
                  labelStyle={styles1.labelStyle}
                  variant={"filled"}
                  error={error?.deliveryRadius}
                />
              </Grid>
              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={minCollectionOrderAmount}
                  setValue={(val) =>
                    handlerOnChange("minimumCollectionOrderAmount", val)
                  }
                  placeholder={""}
                  label={"Minimum Collection Order Amount"}
                  labelStyle={styles1.labelStyle}
                  required={true}
                  variant={"filled"}
                  error={error?.minimumCollectionOrderAmount}
                />
              </Grid>

              <Grid item xs={12} md={3} mb={2}>
                <SettingTextField
                  page={"settings"}
                  value={minDeliveryOrderAmount}
                  setValue={(val) =>
                    handlerOnChange("minimumDeliveryOrderAmount", val)
                  }
                  label={"Minimum Delivery Order Amount"}
                  labelStyle={styles1.labelStyle}
                  placeholder={""}
                  required={true}
                  variant={"filled"}
                  error={error?.minimumDeliveryOrderAmount}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <Grid container item xs={12} md={6} spacing={2} mb={2}>
                <Grid item xs={12}>
                  <label style={styles1.labelStyle}>Delivery Window</label>
                </Grid>
                <Grid item xs={6}>
                  <SettingTimePicker
                    value={deliveryWindowStartTime}
                    disabled={false}
                    inputFormat="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("deliveryWindowStartTime", newValue);
                    }}
                    labelStyle={{ fontSize: 12, fontFamily: "InterMedium" }}
                    inputBaseRootStyle={{ fontSize: 14 }}
                    label={"Start Time"}
                    placeholder={"Time"}
                    backgroundColor={theme_mode.inputBg}
                    borderRadius={"12px"}
                    clearable={true}
                    error={error?.deliveryWindowStartTime}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SettingTimePicker
                    value={deliveryWindowEndTime}
                    disabled={false}
                    inputFormat="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("deliveryWindowEndTime", newValue);
                    }}
                    labelStyle={{ fontSize: 12, fontFamily: "InterMedium" }}
                    inputBaseRootStyle={{ fontSize: 14 }}
                    label={"End Time"}
                    placeholder={"Time"}
                    backgroundColor={theme_mode.inputBg}
                    borderRadius={"12px"}
                    clearable={true}
                    error={error?.deliveryWindowEndTime}
                  />
                </Grid>
              </Grid>

              <Grid container item xs={12} md={6} spacing={2} mb={2}>
                <Grid item xs={12}>
                  <label style={styles1.labelStyle}>Take Away Window</label>
                </Grid>
                <Grid item xs={6}>
                  <SettingTimePicker
                    value={takeAwayWindowStartTime}
                    disabled={false}
                    inputFormat="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("takeAwayWindowStartTime", newValue);
                    }}
                    labelStyle={{ fontSize: 12, fontFamily: "InterMedium" }}
                    inputBaseRootStyle={{ fontSize: 14 }}
                    label={"Start Time"}
                    placeholder={"Time"}
                    backgroundColor={theme_mode.inputBg}
                    borderRadius={"12px"}
                    clearable={true}
                    error={error?.takeAwayWindowStartTime}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SettingTimePicker
                    value={takeAwayWindowEndTime}
                    disabled={false}
                    inputFormat="hh:mm a"
                    onChange={(newValue) => {
                      handlerOnChange("takeAwayWindowEndTime", newValue);
                    }}
                    labelStyle={{ fontSize: 12, fontFamily: "InterMedium" }}
                    inputBaseRootStyle={{ fontSize: 14 }}
                    label={"End Time"}
                    placeholder={"Time"}
                    backgroundColor={theme_mode.inputBg}
                    borderRadius={"12px"}
                    clearable={true}
                    error={error?.takeAwayWindowEndTime}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2} >
              <EmailTemplateHeader
                theme_mode={theme_mode}
                title="Pickup Email Template Settings"
                onClick={() => setShowPickupSection(!showPickupSection)}
                showSection={showPickupSection}
                styles={styles1}
              />

              <Grid container item xs={12} spacing={2} sx={{ display: showPickupSection ? 'flex' : 'none' }}>
                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <ImageColorComponent
                    id={PAGE_CONSTANTS.header_pickup}
                    value={headerImage ? headerImage : headerBackgroundColor}
                    onChange={(mode, val) => handleImageColorFieldChange(PAGE_CONSTANTS.header_pickup, mode, val)}
                    onDelete={(mode) => handleImageColorFieldChange(PAGE_CONSTANTS.header_pickup, mode, null)}
                    label="Header Background"
                    styles={styles1}
                    error={error?.[PAGE_CONSTANTS.header_pickup]}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerTitle}
                    setValue={(val) => setHeaderTitle(val)}
                    required={true}
                    label={"Header Title"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerTitle}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerTitleColor ?? ""}
                    setValue={(val) => setHeaderTitleColor(val)}
                    required={true}
                    label={"Header Title Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerTitleColor}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={headerTitleColor ? headerTitleColor : "#000000"}
                      onChange={(e) => setHeaderTitleColor(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setHeaderTitleColor(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerSubTitle}
                    setValue={(val) => setHeaderSubTitle(val)}
                    required={true}
                    label={"Header Subtitle"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerSubTitle}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerSubTitleColor ?? ""}
                    setValue={(val) => setHeaderSubTitleColor(val)}
                    required={true}
                    label={"Header Subtitle Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerSubTitleColor}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={headerSubTitleColor ? headerSubTitleColor : "#000000"}
                      onChange={(e) => setHeaderSubTitleColor(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setHeaderSubTitleColor(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={helpText}
                    setValue={(val) => setHelpText(val)}
                    required={true}
                    label={"Help Text"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.helpText}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={collectionInfoHeading}
                    setValue={(val) => setCollectionInfoHeading(val)}
                    required={true}
                    label={"Collection Info Heading"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.collectionInfoHeading}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={collectionInfoText}
                    setValue={(val) => setCollectionInfoText(val)}
                    required={true}
                    label={"Collection Info Text"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.collectionInfoText}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <ImageColorComponent
                    id={PAGE_CONSTANTS.footer_pickup}
                    value={footerImage ? footerImage : footerBackgroundColor}
                    onChange={(mode, val) => handleImageColorFieldChange(PAGE_CONSTANTS.footer_pickup, mode, val)}
                    onDelete={(mode) => handleImageColorFieldChange(PAGE_CONSTANTS.footer_pickup, mode, null)}
                    label="Footer Background"
                    styles={styles1}
                    error={error?.[PAGE_CONSTANTS.footer_pickup]}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerTitle}
                    setValue={(val) => setFooterTitle(val)}
                    required={true}
                    label={"Footer Title"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerTitle}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerTitleColor ?? ""}
                    setValue={(val) => setFooterTitleColor(val)}
                    required={true}
                    label={"Footer Title Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerTitleColor}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={footerTitleColor ? footerTitleColor : "#000000"}
                      onChange={(e) => setFooterTitleColor(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setFooterTitleColor(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerSubTitle}
                    setValue={(val) => setFooterSubTitle(val)}
                    placeholder={""}
                    required={true}
                    label={"Footer Subtitle"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerSubTitle}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerSubTitleColor ?? ""}
                    setValue={(val) => setFooterSubTitleColor(val)}
                    placeholder={""}
                    required={true}
                    label={"Footer Subtitle Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerSubTitleColor}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={footerSubTitleColor ? footerSubTitleColor : "#000000"}
                      onChange={(e) => setFooterSubTitleColor(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setFooterSubTitleColor(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={packageField}
                    setValue={(val) => setPackageField(val)}
                    placeholder={""}
                    required={false}
                    label={"Packaging Field"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.packageField}
                  />
                </Grid>

              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={2}>
              <EmailTemplateHeader
                theme_mode={theme_mode}
                title="Delivery Email Template Settings"
                onClick={() => setShowDeliverySection(!showDeliverySection)}
                showSection={showDeliverySection}
                styles={styles1}
              />

              <Grid container item xs={12} spacing={2} sx={{ display: showDeliverySection ? "flex" : "none" }}>
                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <ImageColorComponent
                    id={PAGE_CONSTANTS.header_delivery}
                    value={headerImage_delivery ? headerImage_delivery : headerBackgroundColor_delivery}
                    onChange={(mode, val) => handleImageColorFieldChange(PAGE_CONSTANTS.header_delivery, mode, val)}
                    onDelete={(mode) => handleImageColorFieldChange(PAGE_CONSTANTS.header_delivery, mode, null)}
                    label="Header Background"
                    styles={styles1}
                    error={error?.[PAGE_CONSTANTS.header_delivery]}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerTitle_delivery}
                    setValue={(val) => setHeaderTitle_delivery(val)}
                    required={true}
                    label={"Header Title"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerTitle_delivery}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerTitleColor_delivery ?? ""}
                    setValue={(val) => setHeaderTitleColor_delivery(val)}
                    required={true}
                    label={"Header Title Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerTitleColor_delivery}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={headerTitleColor_delivery ? headerTitleColor_delivery : "#000000"}
                      onChange={(e) => setHeaderTitleColor_delivery(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setHeaderTitleColor_delivery(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerSubTitle_delivery}
                    setValue={(val) => setHeaderSubTitle_delivery(val)}
                    required={true}
                    label={"Header Subtitle"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerSubTitle_delivery}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={headerSubTitleColor_delivery ?? ""}
                    setValue={(val) => setHeaderSubTitleColor_delivery(val)}
                    required={true}
                    label={"Header Subtitle Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.headerSubTitleColor_delivery}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={headerSubTitleColor_delivery ? headerSubTitleColor_delivery : "#000000"}
                      onChange={(e) => setHeaderSubTitleColor_delivery(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setHeaderSubTitleColor_delivery(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={helpText_delivery}
                    setValue={(val) => setHelpText_delivery(val)}
                    required={true}
                    label={"Help Text"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.helpText_delivery}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={collectionInfoHeading_delivery}
                    setValue={(val) => setCollectionInfoHeading_delivery(val)}
                    required={true}
                    label={"Collection Info Heading"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.collectionInfoHeading_delivery}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={collectionInfoText_delivery}
                    setValue={(val) => setCollectionInfoText_delivery(val)}
                    required={true}
                    label={"Collection Info Text"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.collectionInfoText_delivery}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <ImageColorComponent
                    id={PAGE_CONSTANTS.footer_delivery}
                    value={footerImage_delivery ? footerImage_delivery : footerBackgroundColor_delivery}
                    onChange={(mode, val) => handleImageColorFieldChange(PAGE_CONSTANTS.footer_delivery, mode, val)}
                    onDelete={(mode) => handleImageColorFieldChange(PAGE_CONSTANTS.footer_delivery, mode, null)}
                    label="Footer Background"
                    styles={styles1}
                    error={error?.[PAGE_CONSTANTS.footer_delivery]}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerTitle_delivery}
                    setValue={(val) => setFooterTitle_delivery(val)}
                    required={true}
                    label={"Footer Title"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerTitle_delivery}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerTitleColor_delivery ?? ""}
                    setValue={(val) => setFooterTitleColor_delivery(val)}
                    required={true}
                    label={"Footer Title Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerTitleColor_delivery}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={footerTitleColor_delivery ? footerTitleColor_delivery : "#000000"}
                      onChange={(e) => setFooterTitleColor_delivery(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setFooterTitleColor_delivery(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerSubTitle_delivery}
                    setValue={(val) => setFooterSubTitle_delivery(val)}
                    placeholder={""}
                    required={true}
                    label={"Footer Subtitle"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerSubTitle_delivery}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={footerSubTitleColor_delivery ?? ""}
                    setValue={(val) => setFooterSubTitleColor_delivery(val)}
                    placeholder={""}
                    required={true}
                    label={"Footer Subtitle Color"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.footerSubTitleColor_delivery}
                    leftView={<Input
                      type="color"
                      sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                      value={footerSubTitleColor_delivery ? footerSubTitleColor_delivery : "#000000"}
                      onChange={(e) => setFooterSubTitleColor_delivery(e.target.value)} />}

                    rightView={<InputAdornment position="end">
                      <IconButton onClick={() => setFooterSubTitleColor_delivery(null)}>
                        <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                      </IconButton>
                    </InputAdornment>}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={4} xl={3} mb={2}>
                  <SettingTextField
                    value={packageField_delivery}
                    setValue={(val) => setPackageField_delivery(val)}
                    placeholder={""}
                    required={false}
                    label={"Packaging Field"}
                    labelStyle={styles1.labelStyle}
                    variant={"filled"}
                    error={error?.packageField_delivery}
                  />
                </Grid>

              </Grid>
            </Grid>

            <Grid container item xs={12} justifyContent={"flex-end"} mt={2}>
              <Grid item xs={12} md={5}>
                <Box className={styles?.["btn-main-View"]} mt={2}>
                  <Button
                    type="button"
                    className={styles?.["cancel-btn"]}
                    sx={styles1.cancelBtn}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    disabled={saveBtnDisabled}
                    className={styles?.["save-btn"]}
                    sx={{
                      ...styles1.saveBtn,
                      backgroundColor: saveBtnDisabled
                        ? theme_mode.btnDisabledBg
                        : theme_mode.btnBg4,
                    }}
                    onClick={onSaveGeneralSettings}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Container >
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRestaurantDetail: (data) => dispatch(updateRestaurantDetail(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantDetail: state.userData.restaurantDetail,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TakeAwaySettings);

const styles2 = (Theme) => ({
  labelStyle: {
    color: "#fff",
    fontSize: 14,
    fontFamily: "InterSemiBold",
  },
  saveBtn: {
    backgroundColor: Theme.btnBg4,
    color: Theme.btnText,
    ml: 1,
    "&:hover": {
      backgroundColor: `${Theme.btnBg4} !important`,
    },
  },
  cancelBtn: {
    backgroundColor: Theme.btnBg5,
    border: `2px solid ${Theme.btnBg5Border}`,
    color: Theme.text,
    mr: 1,
    "&:hover": {
      backgroundColor: `${Theme.btnBg4} !important`,
    },
  },
});
