import React, { useState } from 'react'
import Box from "@mui/material/Box"
import Input from "@mui/material/Input"
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from '@mui/material/IconButton';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import PaletteOutlinedIcon from '@mui/icons-material/PaletteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import SettingTextField from "../../../components/SettingTextField";
import { ReactComponent as DeleteIcon } from "../../../assets/images/delete2.svg";

export const MODES = {
    IMAGE: 'image',
    COLOR: 'color'
}

const ImageColorComponent = ({ id, value, onChange, onDelete, label, required = true, placeholder = "", styles, error, sx = {} }) => {
    const [mode, setMode] = useState(value && (typeof value !== "string" && (value.link || value.name)) ? MODES.IMAGE : MODES.COLOR);

    const handleValueChange = (val) => {
        if (mode === MODES.COLOR) {
            onChange(mode, val);
        }
    }

    const handleDelete = () => onDelete(mode);
    const handleModeChange = (mode) => {
        setMode(mode);
    }

    return (
        <SettingTextField
            value={mode === MODES.IMAGE ? value?.link ?? value?.name ?? "" : typeof value === "string" ? value : ""}
            setValue={handleValueChange}
            placeholder={placeholder}
            required={required}
            disabled={mode === MODES.IMAGE}
            label={label}
            labelStyle={styles.labelStyle}
            variant={"filled"}
            error={error}
            textInputCss={{ textTransform: 'uppercase', '& input': { paddingLeft: mode === MODES.IMAGE ? '2.5rem !important' : 0 }, ...sx }}
            leftView={
                mode === MODES.IMAGE ? <Box sx={{ position: 'relative' }}>
                    <FileUploadOutlinedIcon sx={{ position: 'absolute', zIndex: 0, top: '-24px', width: '2rem', height: '2em', left: 0 }} />
                    <Input
                        type="file"
                        sx={{ position: 'absolute', opacity: 0, zIndex: 1, top: '-24px', left: 0, width: '2rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                        inputProps={{ accept: "image/*", multiple: false }}
                        value={""}
                        onChange={(e) => onChange(mode, e.target.files[0])} />
                </Box> : <Input
                    type="color"
                    sx={{ width: '2rem', marginRight: '0.75rem', padding: 0, '& input': { height: '2em', borderRadius: '12px' } }}
                    value={value ? value : "#000000"}
                    onChange={(e) => handleValueChange(e.target.value)} />
            }

            rightView={< InputAdornment position="end" >
                <IconButton onClick={handleDelete}>
                    <DeleteIcon width={18} height={18} viewBox="0 0 12 12" />
                </IconButton>
                <IconButton
                    sx={{
                        backgroundColor: mode === MODES.IMAGE ? "#ffffff" : "#transparent",
                        borderRadius: "12px",
                        padding: '4px',
                        marginLeft: '2px',
                        '&:hover': {
                            backgroundColor: "#ffffff"
                        }
                    }}
                    onClick={() => handleModeChange(MODES.IMAGE)}
                >
                    <ImageOutlinedIcon sx={{ stroke: mode === MODES.IMAGE ? "#000000" : "transparent", '&:hover': { stroke: "#000000" } }} />
                </IconButton>
                <IconButton
                    sx={{
                        backgroundColor: mode === MODES.COLOR ? "#ffffff" : "transparent",
                        borderRadius: "12px",
                        padding: '4px',
                        marginLeft: '2px',
                        '&:hover': {
                            backgroundColor: "#ffffff"
                        }
                    }}
                    onClick={() => handleModeChange(MODES.COLOR)}
                >
                    <PaletteOutlinedIcon sx={{ stroke: mode === MODES.COLOR ? "#000000" : "transparent", '&:hover': { stroke: "#000000" } }} />
                </IconButton>
            </InputAdornment >}
        />
    )
}

export default ImageColorComponent
