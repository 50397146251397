import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import moment from "moment";

import styles from "./PinLogin.module.css";

import { connect } from "react-redux";
import {
  updatePinLogin,
  updateUserRolesList,
} from "../../redux/actions/userDataActions";

import * as Api from "../../api/index";

import Calculator from "../../components/Calculator";
import LogoutConfirmModal from "../../components/LogoutConfirmModal";
import AlertMsg from "../../components/AlertMsg";
import Loader from "../../components/Loader";

import { THEME_MODE } from "../../constants/Theme";

import { ReactComponent as LogoIcon } from "../../assets/images/logo2.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/logout2.svg";

const CALC_NO = [1, 2, 3, 4, 5, 6, 7, 8, 9, "Clear", 0, "x"];
const VIEW_WIDTH = window.innerWidth / 2;
const XS_CALC_WIDTH = (VIEW_WIDTH * 20) / 100;
const CALC_WIDTH = (VIEW_WIDTH * 14) / 100;
const FONT_SIZE = {
  xs: XS_CALC_WIDTH / 4,
  sm: XS_CALC_WIDTH / 5,
  md: CALC_WIDTH / 5.5,
};
const BTN_FONT_SIZE = {
  xs: XS_CALC_WIDTH / 4,
  sm: XS_CALC_WIDTH / 5,
  md: CALC_WIDTH / 6,
};

const PinLogin = (props) => {
  const theme_mode = THEME_MODE[props.themeMode];
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [pinValue, setPinValue] = useState("");
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [dateTIme, setDateTIme] = useState(
    moment().format("dddd MMMM DD, YYYY h:mm A")
  );
  const [msgAlert, setMsgAlert] = useState({
    open: false,
    message: "",
    msgType: "error",
  });

  useEffect(() => {
    const interval = setInterval(
      () => setDateTIme(moment().format("dddd MMMM DD, YYYY h:mm A")),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (props.userRolesList.length == 0) {
      getUserList();
    }
  }, []);

  useEffect(() => {
    if (pinValue.toString().length == 4) {
      onClockInClick();
    }
  }, [pinValue]);

  const onEnterPinValue = (val) => {
    if (val.toString().length <= 4) {
      setPinValue(val);
    }
  };

  const onClockInClick = () => {
    if (pinValue.toString().length == 4) {
      getUserByPin("clock_in");
    } else {
      let err = "Invalid Pin";
      if (pinValue == "") {
        err = "Enter Pin";
      }
      setMsgAlert({ open: true, message: err, msgType: "error" });
    }
  };

  // const onClockOutClick = () => {
  //   if (pinValue.toString().length == 4) {
  //     getUserByPin("clock_out");
  //   } else {
  //     let err = "Invalid Pin";
  //     if (pinValue == "") {
  //       err = "Enter Pin";
  //     }
  //     setMsgAlert({ open: true, message: err, msgType: "error" });
  //   }
  // };

  const getUserByPin = (type) => {
    setIsLoading(true);
    let user_list = props.userRolesList.filter(
      (x) =>
        x?.userDetails?.length &&
        typeof x?.userDetails[0]?.pin != "undefined" &&
        x?.userDetails[0]?.pin == pinValue
    );
    if (user_list.length) {
      let data = user_list[0];

      const user_detail = {
        name: data.userDetails?.[0]?.name,
        email: data.userDetails?.[0]?.email,
        phoneNumber: data.userDetails?.[0]?.phoneNumber,
      };

      props.updatePinLogin({
        pinLogin: true,
        pinUserId: data.userDetails?.[0]?.["_id"],
        userRoleId: data["_id"],
        userRole: data["_idUserRoles"],
        userDetail: user_detail,
      });
    } else {
      setPinValue("");
      setIsLoading(false);
      setMsgAlert({
        open: true,
        message: "User not found",
        msgType: "error",
      });
    }
  };

  const onConfirmLogout = () => {
    navigate("/logout");
  };

  const getUserList = () => {
    setIsLoading(true);
    Api.getUserRolesList(props.restaurantId, null).then((response) => {
      if (response.success) {
        let data1 = response.data;
        props.updateUserRolesList(data1.rows);
      } else {
        setMsgAlert({ open: true, message: response.msg, msgType: "error" });
      }
      setIsLoading(false);
    });
  };

  if (isLoading) {
    return <Loader height={{ height: window.innerHeight - 50 }} />;
  }

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      className={props.themeMode == "dark" ? styles.bgView : styles.bgView2}
    >
      <Box
        className={styles.logoView}
        sx={{
          path: {
            fill: theme_mode.iconBg,
          },
        }}
      >
        <Typography
          className={styles.poweredByText}
          style={{ color: theme_mode.text }}
        >
          Powered by
        </Typography>
        <LogoIcon height={15} width={100} />
      </Box>
      <Box className={styles.pinLoginBg}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={
            props.themeMode == "dark"
              ? require("../../assets/images/pin_login_dark_bg.png")
              : require("../../assets/images/pin_login_bg.png")
          }
        />
      </Box>
      <Box
        className={styles.logoutView}
        onClick={() => setConfirmModalVisible(true)}
        sx={{
          backgroundColor: theme_mode.btnBg6,
          path: { fill: theme_mode.iconBg },
        }}
      >
        <LogoutIcon width={18} height={18} />
        <Typography
          ml={1}
          className={styles.logoutText}
          sx={{ color: theme_mode.text }}
        >
          Logout
        </Typography>
      </Box>
      <Grid container item xs={12} className={styles.mainView}>
        <Grid
          item
          xs={12}
          sm={6}
          mt={{ xs: 4, sm: 0 }}
          container
          direction={"column"}
          className={styles.leftView}
        >
          {props.restaurantDetail?.logo ? (
            <Box mb={3} className={styles.logoImgView}>
              <img
                src={props.restaurantDetail?.logo}
                className={styles.logoImg}
              />
            </Box>
          ) : null}
          <Box className={styles.btnView}>
            {/*<Box className={styles.clockInOutBtnView}>*/}
            {/*  <Box*/}
            {/*    onClick={() => onClockInClick()}*/}
            {/*    className={styles.clockInBtnView}*/}
            {/*    sx={{ backgroundColor: theme_mode.theme }}*/}
            {/*  >*/}
            {/*    <Typography*/}
            {/*      className={styles.clockInBtnText}*/}
            {/*      sx={{ color: theme_mode.text, fontSize: BTN_FONT_SIZE }}*/}
            {/*    >*/}
            {/*      Clock In*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}
            {/*  <Box*/}
            {/*    onClick={() => onClockOutClick()}*/}
            {/*    className={styles.clockOutBtnView}*/}
            {/*    sx={{ backgroundColor: theme_mode.headerBg }}*/}
            {/*  >*/}
            {/*    <Typography*/}
            {/*      className={styles.clockOutBtnText}*/}
            {/*      sx={{ color: theme_mode.lightText, fontSize: BTN_FONT_SIZE }}*/}
            {/*    >*/}
            {/*      Clock Out*/}
            {/*    </Typography>*/}
            {/*  </Box>*/}
            {/*</Box>*/}
            <Box
              mt={2}
              className={styles.noteBtnView}
              sx={{ backgroundColor: theme_mode.btnBg3 }}
            >
              <Typography
                className={styles.noteBtnText}
                sx={{ color: theme_mode.btnText, fontSize: BTN_FONT_SIZE }}
              >
                Notes
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          mb={{ xs: 10, sm: 0 }}
          container
          direction={"column"}
          className={styles.rightView}
        >
          <Typography
            className={styles.timeText}
            sx={{ color: theme_mode.text }}
          >
            {moment(dateTIme, "dddd MMMM DD, YYYY h:mm A").format("h:mm A")}
          </Typography>
          <Typography
            className={styles.dateText}
            sx={{ color: theme_mode.text }}
          >
            {moment(dateTIme, "dddd MMMM DD, YYYY h:mm A").format(
              "dddd MMMM DD, YYYY"
            )}
          </Typography>
          <Box className={styles.pinMainView} mt={3} mb={2}>
            {Array(pinValue.length)
              .fill(0)
              .map((d, i) => {
                return (
                  <Box
                    key={i}
                    mr={1}
                    className={styles.pinView}
                    sx={{ backgroundColor: theme_mode.pinBg }}
                  />
                );
              })}
          </Box>
          <Box
            className={styles.calcView}
            sx={{
              width: {
                xs: XS_CALC_WIDTH * 3 + 3 * 5,
                md: CALC_WIDTH * 3 + 3 * 5,
              },
            }}
          >
            <Calculator
              calcList={CALC_NO}
              width={{ xs: XS_CALC_WIDTH, md: CALC_WIDTH }}
              height={{ xs: XS_CALC_WIDTH - 20, md: CALC_WIDTH - 20 }}
              mainViewStyle={{ justifyContent: "space-between" }}
              textStyle={{ fontSize: FONT_SIZE }}
              calcStyle={{ marginLeft: 0 }}
              currentVal={pinValue}
              emptyValue={true}
              initialZero={true}
              onUpdate={onEnterPinValue}
              themeMode={props.themeMode}
            />
          </Box>
        </Grid>
      </Grid>
      <LogoutConfirmModal
        modalVisible={confirmModalVisible}
        msg={"Are you sure you want to logout?"}
        btnText={"Logout"}
        closeBtnText={"Cancel"}
        themeMode={props.themeMode}
        onSubmit={() => onConfirmLogout()}
        onClose={() => setConfirmModalVisible(false)}
      />
      <AlertMsg
        msgAlert={msgAlert}
        onCloseAlertMsg={() =>
          setMsgAlert({ open: false, message: "", msgType: "error" })
        }
      />
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePinLogin: (data) => dispatch(updatePinLogin(data)),
    updateUserRolesList: (data) => dispatch(updateUserRolesList(data)),
  };
};

const mapStateToProps = (state) => {
  return {
    themeMode: state.userData.themeMode,
    restaurantId: state.userData.restaurantId,
    restaurantDetail: state.userData.restaurantDetail,
    userRolesList: state.userData.userRolesList,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinLogin);
